<template>
    <div>
        <div>
            <div class="tag-con-head">
                <div class="tag-label">配置文本</div>
                <div v-if="drawerNode.textItems">
                    <el-button type="text" size="mini" @click="addTextItems">添加</el-button>
                    <el-button :style="drawerNode.textItems.length==1?'color:#ccc;':'color:red;'" type="text" size="mini" @click="delTextItems" :disabled="drawerNode.textItems.length==1">删除</el-button>
                </div>
            </div>
            <div class="tag-content" v-for="(item, index) in drawerNode.textItems" :key="index">
                <el-form-item label="数据类型" style="margin-bottom:10px;">
                    <el-select size="mini" v-model="item.dataType" placeholder="请选择数据类型" @change="changeTextDataType">
                        <el-option
                            v-for="item in dataType"
                            :key="item"
                            :label="item"
                            :value="item"></el-option>
                    </el-select>
                </el-form-item>
                <div v-show="item.dataType">
                    <!-- Bool类型 -->
                    <div v-show="item.dataType=='Bool'">
                        <el-form-item label="运算符">
                            <el-radio-group v-model="item.boolData.formulaType" @change="changeTextFormulaType">
                                <el-radio :label="'empty'">无</el-radio>
                                <el-radio :label="'&'">&</el-radio>
                                <el-radio :label="'||'">||</el-radio>
                                <el-radio :label="'!'">!</el-radio>
                            </el-radio-group>
                        </el-form-item>
                        <!-- 运算符为 '无' 和 '!' -->
                        <div v-show="item.boolData.formulaType=='empty'||item.boolData.formulaType=='!'" class="con-middle-tag-head" style="margin: 10px 0;">
                            <div style="font-size:14px;">选择Tag</div>
                            <div class="">
                                <el-button v-if="!item.boolData.tag" size="mini" style="width: 60px;margin: 0 10px;text-align:center;" class="lube-btn" type="primary" @click.prevent="selectTextItemTag(item)">选Tag</el-button>
                                <span v-else class="close-lube-btn" style="width: 60px;margin: 0 10px;">
                                    {{item.boolData.tag}}
                                    <span class="el-icon-error close-lube" @click="clearTextItemTag(item)"></span>
                                </span>
                            </div>
                        </div>
                        <!-- 运算符为 '&' -->
                        <div v-show="item.boolData.formulaType=='&'">
                            <div class="flex-change">
                                <div style="font-size:14px;font-weight:bold;margin-left:10px;">配置Tag</div>
                                <div v-if="item.boolData.boolTagData">
                                    <el-button type="text" @click="btAddTag(item.boolData.boolTagData)">新增</el-button>
                                    <el-button :style="item.boolData.boolTagData.length==2?'color:#ccc;':'color:red;'" type="text" :disabled="item.boolData.boolTagData.length==2" @click="btDelTag(item.boolData.boolTagData)">删除</el-button>
                                </div>
                            </div>
                            <div class="flex-change" style="flex-wrap:wrap;margin-left: 5px;">
                                <div style="width:50%;margin-bottom:10px;" v-for="(btItem, btIndex) in item.boolData.boolTagData" :key="btIndex">
                                    <div style="display:flex;">
                                        <el-button v-if="!btItem.tag" size="mini" class="lube-btn" type="primary" @click.prevent="selectItemTag(btItem)">选择Tag</el-button>
                                        <span v-else class="close-lube-btn">
                                            {{btItem.tag}}
                                            <span class="el-icon-error close-lube" @click="clearBtItemTag(btItem)"></span>
                                        </span>
                                        <div v-show="item.boolData.boolTagData.length-1!=btIndex" style="font-size:14px;width:25px;line-height:27px;text-align:center;background:#efefef;border-radius:5px;margin: 0 10px;">&</div>
                                    </div>
                                            
                                    <!-- 运算符为 '&' 的侧滑选择Tag -->
                                    <el-drawer
                                        title="选择Tag"
                                        :with-header="false"
                                        :visible.sync="btItem.tagDrawer"
                                        direction="rtl"
                                        size="800px">
                                        <div class="drawer-title">选择Tag</div>
                                        <div class="drawer-content table-tree">
                                        <el-table :data="tagTableData" height="700">
                                            <el-table-column label="勾选" width="70">
                                            <template slot-scope="scope">
                                                <el-radio-group v-model="btItem.tagRadio" @change="checkTagChange(scope.row)">
                                                <el-radio :label="scope.row.id">{{''}}</el-radio>
                                                </el-radio-group>
                                            </template>
                                            </el-table-column>
                                            <el-table-column prop="number" label="序号" width="50"></el-table-column>
                                            <el-table-column prop="dataType" label="数据类型"></el-table-column>
                                            <el-table-column prop="pointName" label="点名"></el-table-column>
                                            <el-table-column prop="describe" label="Tag描述" width="240"></el-table-column>
                                            <el-table-column prop="readWriteType" label="读写方向"></el-table-column>
                                            <el-table-column prop="cycle" label="采集周期"></el-table-column>
                                        </el-table>
                                        </div>
                                        <div class="drawer-bottom">
                                        <el-button size="small" type="primary" @click="btItem.tagDrawer=false">取消</el-button>
                                        <el-button
                                            size="small"
                                            type="primary"
                                            @click="tagBtItemDrawerOK(btItem)"
                                            class="drawer-bottom-ok">确 定</el-button>
                                        </div>
                                    </el-drawer>
                                </div>
                            </div>
                        </div>
                        <!-- 运算符为 '||' -->
                        <div v-show="item.boolData.formulaType=='||'">
                            <div class="flex-change">
                                <div style="font-size:14px;font-weight:bold;margin-left:10px;">配置Tag</div>
                                <div v-if="item.boolData.boolTagData">
                                    <el-button type="text" @click="btAddTag(item.boolData.boolTagData)">新增</el-button>
                                    <el-button :style="item.boolData.boolTagData.length==2?'color:#ccc;':'color:red;'" type="text" :disabled="item.boolData.boolTagData.length==2" @click="btDelTag(item.boolData.boolTagData)">删除</el-button>
                                </div>
                            </div>
                            <div class="flex-change" style="flex-wrap:wrap;margin-left: 5px;">
                                <div style="width:50%;margin-bottom:10px;" v-for="(btItem, btIndex) in item.boolData.boolTagData" :key="btIndex">
                                    <div style="display:flex;">
                                        <el-button v-if="!btItem.tag" size="mini" class="lube-btn" type="primary" @click.prevent="selectItemTag(btItem)">选择Tag</el-button>
                                        <span v-else class="close-lube-btn">
                                            {{btItem.tag}}
                                            <span class="el-icon-error close-lube" @click="clearBtItemTag(btItem)"></span>
                                        </span>
                                        <div v-show="item.boolData.boolTagData.length-1!=btIndex" style="font-size:14px;width:25px;line-height:27px;text-align:center;background:#efefef;border-radius:5px;margin: 0 10px;">||</div>
                                    </div>
                                            
                                    <!-- 运算符为 '||' 的侧滑选择Tag -->
                                    <el-drawer
                                        title="选择Tag"
                                        :with-header="false"
                                        :visible.sync="btItem.tagDrawer"
                                        direction="rtl"
                                        size="800px">
                                        <div class="drawer-title">选择Tag</div>
                                        <div class="drawer-content table-tree">
                                        <el-table :data="tagTableData" height="700">
                                            <el-table-column label="勾选" width="70">
                                            <template slot-scope="scope">
                                                <el-radio-group v-model="btItem.tagRadio" @change="checkTagChange(scope.row)">
                                                <el-radio :label="scope.row.id">{{''}}</el-radio>
                                                </el-radio-group>
                                            </template>
                                            </el-table-column>
                                            <el-table-column prop="number" label="序号" width="50"></el-table-column>
                                            <el-table-column prop="dataType" label="数据类型"></el-table-column>
                                            <el-table-column prop="pointName" label="点名"></el-table-column>
                                            <el-table-column prop="describe" label="Tag描述" width="240"></el-table-column>
                                            <el-table-column prop="readWriteType" label="读写方向"></el-table-column>
                                            <el-table-column prop="cycle" label="采集周期"></el-table-column>
                                        </el-table>
                                        </div>
                                        <div class="drawer-bottom">
                                        <el-button size="small" type="primary" @click="btItem.tagDrawer=false">取消</el-button>
                                        <el-button
                                            size="small"
                                            type="primary"
                                            @click="tagBtItemDrawerOK(btItem)"
                                            class="drawer-bottom-ok">确 定</el-button>
                                        </div>
                                    </el-drawer>
                                </div>
                            </div>
                        </div>
                        <el-form-item label="单位" style="margin-bottom:10px;">
                            <el-input size="mini" v-model="item.boolData.unit" placeholder="单位" @change="changeTextUnit"></el-input>
                        </el-form-item>
                        <el-form-item label="Tag描述" style="margin-bottom:10px;">
                            <el-input size="mini" v-model="item.boolData.tagDescribe" placeholder="描述" @change="changeTextDesc"></el-input>
                        </el-form-item>
                        <div class="tag-con-head">
                            <span class="tag-label">Tag含义</span>
                            <div v-if="item.boolData.values">
                                <el-button type="text" size="mini" @click="addTagExplain(item.boolData.values)">新增</el-button>
                                <el-button :style="item.boolData.values.length==1?'color:#ccc;':'color:red;'" type="text" size="mini" @click="delTagExplain(item.boolData.values)" :disabled="item.boolData.values.length==1">删除</el-button>
                            </div>
                        </div>
                        <div v-for="(val, key) in item.boolData.values" :key="key">
                            <el-form-item label="编码" style="margin-bottom: 10px;">
                                <el-input size="mini" v-model="val.code" placeholder="请输入编码" @change="changeTextCode"></el-input>
                            </el-form-item>
                            <el-form-item label="含义" style="margin-bottom: 10px;">
                                <el-input size="mini" v-model="val.meaning" placeholder="请输入含义" @change="changeTextMean"></el-input>
                            </el-form-item>
                        </div>
                    </div>
                    <!-- Real类型 -->
                    <div v-show="item.dataType=='Real'">
                        <el-form-item label="运算符">
                            <el-radio-group v-model="item.realData.formulaType" @change="changeTextFormulaType">
                                <el-radio :label="'empty'">无</el-radio>
                                <el-radio :label="'+'">+</el-radio>
                                <el-radio :label="'-'">-</el-radio>
                            </el-radio-group>
                        </el-form-item>
                        <!-- 运算符为 '无' -->
                        <div v-show="item.realData.formulaType=='empty'" class="con-middle-tag-head" style="margin: 10px 0;">
                            <div style="font-size:14px;">选择Tag</div>
                            <div class="">
                                <el-button v-if="!item.realData.tag" size="mini" style="width: 60px;margin: 0 10px;text-align:center;" class="lube-btn" type="primary" @click.prevent="selectTextItemTag(item)">选Tag</el-button>
                                <span v-else class="close-lube-btn" style="width: 60px;margin: 0 10px;">
                                    {{item.realData.tag}}
                                    <span class="el-icon-error close-lube" @click="clearTextItemTag(item)"></span>
                                </span>
                            </div>
                        </div>
                        <!-- 运算符为 '+' -->
                        <div v-show="item.realData.formulaType=='+'">
                            <div style="display:flex;justify-content:space-between;align-items:center;">
                                <div style="font-size:14px;font-weight:bold;margin-left:10px;">配置Tag</div>
                                <div v-if="item.realData.realTagData">
                                    <el-button type="text" @click="rtAddTag(item.realData.realTagData)">新增</el-button>
                                    <el-button :style="item.realData.realTagData.length==2?'color:#ccc;':'color:red;'" type="text" :disabled="item.realData.realTagData.length==2" @click="rtDelTag(item.realData.realTagData)">删除</el-button>
                                </div>
                            </div>
                            <div class="flex-change" style="flex-wrap:wrap;margin-left: 5px;">
                                <div style="width:50%;margin-bottom:10px;" v-for="(rtItem, rtIndex) in item.realData.realTagData" :key="rtIndex">
                                    <div style="display:flex;">
                                        <el-button v-if="!rtItem.tag" size="mini" class="lube-btn" type="primary" @click.prevent="selectItemTag(rtItem)">选择Tag</el-button>
                                        <span v-else class="close-lube-btn">
                                            {{rtItem.tag}}
                                            <span class="el-icon-error close-lube" @click="clearRtItemTag(rtItem)"></span>
                                        </span>
                                        <div v-show="item.realData.realTagData.length-1!=rtIndex" style="font-size:14px;width:25px;line-height:27px;text-align:center;background:#efefef;border-radius:5px;margin: 0 10px;">+</div>
                                    </div>
                                    
                                    <!-- 运算符为 '+' 的侧滑选择Tag -->
                                    <el-drawer
                                        title="选择Tag"
                                        :with-header="false"
                                        :visible.sync="rtItem.tagDrawer"
                                        direction="rtl"
                                        size="800px">
                                        <div class="drawer-title">选择Tag</div>
                                        <div class="drawer-content table-tree">
                                        <el-table :data="tagTableData" height="700">
                                            <el-table-column label="勾选" width="70">
                                            <template slot-scope="scope">
                                                <el-radio-group v-model="rtItem.tagRadio" @change="checkTagChange(scope.row)">
                                                <el-radio :label="scope.row.id">{{''}}</el-radio>
                                                </el-radio-group>
                                            </template>
                                            </el-table-column>
                                            <el-table-column prop="number" label="序号" width="50"></el-table-column>
                                            <el-table-column prop="dataType" label="数据类型"></el-table-column>
                                            <el-table-column prop="pointName" label="点名"></el-table-column>
                                            <el-table-column prop="describe" label="Tag描述" width="240"></el-table-column>
                                            <el-table-column prop="readWriteType" label="读写方向"></el-table-column>
                                            <el-table-column prop="cycle" label="采集周期"></el-table-column>
                                        </el-table>
                                        </div>
                                        <div class="drawer-bottom">
                                        <el-button size="small" type="primary" @click="rtItem.tagDrawer=false">取消</el-button>
                                        <el-button
                                            size="small"
                                            type="primary"
                                            @click="tagRtItemDrawerOK(rtItem)"
                                            class="drawer-bottom-ok">确 定</el-button>
                                        </div>
                                    </el-drawer>
                                </div>
                            </div>
                        </div>
                        <!-- 运算符为 '-' -->
                        <div v-show="item.realData.formulaType=='-'">
                            <div style="display:flex;justify-content:space-between;align-items:center;">
                                <div style="font-size:14px;font-weight:bold;margin-left:10px;">配置Tag</div>
                                <div v-if="item.realData.realTagData">
                                    <el-button type="text" @click="rtAddTag(item.realData.realTagData)">新增</el-button>
                                    <el-button :style="item.realData.realTagData.length==2?'color:#ccc;':'color:red;'" type="text" :disabled="item.realData.realTagData.length==2" @click="rtDelTag(item.realData.realTagData)">删除</el-button>
                                </div>
                            </div>
                            <div class="flex-change" style="flex-wrap:wrap;margin-left: 5px;">
                                <div style="width:50%;margin-bottom:10px;" v-for="(rtItem, rtIndex) in item.realData.realTagData" :key="rtIndex">
                                    <div style="display:flex;">
                                        <el-button v-if="!rtItem.tag" size="mini" class="lube-btn" type="primary" @click.prevent="selectItemTag(rtItem)">选择Tag</el-button>
                                        <span v-else class="close-lube-btn">
                                            {{rtItem.tag}}
                                            <span class="el-icon-error close-lube" @click="clearRtItemTag(rtItem)"></span>
                                        </span>
                                        <div v-show="item.realData.realTagData.length-1!=rtIndex" style="font-size:14px;width:25px;line-height:27px;text-align:center;background:#efefef;border-radius:5px;margin: 0 10px;">-</div>
                                    </div>
                                    
                                    <!-- 运算符为 '-' 的侧滑选择Tag -->
                                    <el-drawer
                                        title="选择Tag"
                                        :with-header="false"
                                        :visible.sync="rtItem.tagDrawer"
                                        direction="rtl"
                                        size="800px">
                                        <div class="drawer-title">选择Tag</div>
                                        <div class="drawer-content table-tree">
                                        <el-table :data="tagTableData" height="700">
                                            <el-table-column label="勾选" width="70">
                                            <template slot-scope="scope">
                                                <el-radio-group v-model="rtItem.tagRadio" @change="checkTagChange(scope.row)">
                                                <el-radio :label="scope.row.id">{{''}}</el-radio>
                                                </el-radio-group>
                                            </template>
                                            </el-table-column>
                                            <el-table-column prop="number" label="序号" width="50"></el-table-column>
                                            <el-table-column prop="dataType" label="数据类型"></el-table-column>
                                            <el-table-column prop="pointName" label="点名"></el-table-column>
                                            <el-table-column prop="describe" label="Tag描述" width="240"></el-table-column>
                                            <el-table-column prop="readWriteType" label="读写方向"></el-table-column>
                                            <el-table-column prop="cycle" label="采集周期"></el-table-column>
                                        </el-table>
                                        </div>
                                        <div class="drawer-bottom">
                                        <el-button size="small" type="primary" @click="rtItem.tagDrawer=false">取消</el-button>
                                        <el-button
                                            size="small"
                                            type="primary"
                                            @click="tagRtItemDrawerOK(rtItem)"
                                            class="drawer-bottom-ok">确 定</el-button>
                                        </div>
                                    </el-drawer>
                                </div>
                            </div>
                        </div>
                        <el-form-item label="单位" style="margin-bottom:10px;">
                            <el-input size="mini" v-model="item.realData.unit" placeholder="单位" @change="changeTextUnit"></el-input>
                        </el-form-item>
                        <el-form-item label="Tag描述" style="margin-bottom:10px;">
                            <el-input size="mini" v-model="item.realData.tagDescribe" placeholder="描述" @change="changeTextDesc"></el-input>
                        </el-form-item>
                    </div>
                </div>
                
                <!-- 侧滑选择Tag -->
                <el-drawer
                    title="选择Tag"
                    :with-header="false"
                    :visible.sync="item.textItemsDrawer"
                    direction="rtl"
                    size="800px">
                    <div class="drawer-title">选择Tag</div>
                    <div class="drawer-content table-tree">
                    <el-table :data="tagTableData" height="700">
                        <el-table-column label="勾选" width="70">
                        <template slot-scope="scope">
                            <el-radio-group v-model="item.textItemsRadio" @change="checkTagChange(scope.row)">
                            <el-radio :label="scope.row.id">{{''}}</el-radio>
                            </el-radio-group>
                        </template>
                        </el-table-column>
                        <el-table-column prop="number" label="序号" width="50"></el-table-column>
                        <el-table-column prop="dataType" label="数据类型"></el-table-column>
                        <el-table-column prop="pointName" label="点名"></el-table-column>
                        <el-table-column prop="describe" label="Tag描述" width="240"></el-table-column>
                        <el-table-column prop="readWriteType" label="读写方向"></el-table-column>
                        <el-table-column prop="cycle" label="采集周期"></el-table-column>
                    </el-table>
                    </div>
                    <div class="drawer-bottom">
                    <el-button size="small" type="primary" @click="item.textItemsDrawer=false">取消</el-button>
                    <el-button
                        size="small"
                        type="primary"
                        @click="tagTextItemDrawerOK(item)"
                        class="drawer-bottom-ok">确 定</el-button>
                    </div>
                </el-drawer>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            dataType: ['Bool','Real'],//文本 -> 数据类型
            tagSelectObj: {},
        }
    },
    props: {
        selectCell:{
            type: String | Object
        },
        drawerNode: {
            type: Object
        },
        tagTableData: {
            type: Array
        }
    },
    methods: {
        // 勾选Tag
        checkTagChange(row) {
            this.tagSelectObj = row
        },
        // [动画]循环Tag选择
        selectItemTag(item) {
            item.tagDrawer = true
        },
        // 管道流水Bool运算符选择多个Tag
        btAddTag(arr) {
            arr.push({
                tag: '',
                tagDrawer: false,
                tagRadio: '',
            })
        },
        btDelTag(arr) {
            arr.pop()
        },
        clearBtItemTag(item) {
            item.tag = null
        },
        tagBtItemDrawerOK(item) {
            item.tag = this.tagSelectObj.pointName
            item.tagDrawer = false
        },
        // 管道流水Real运算符选择多个Tag
        rtAddTag(arr) {
            arr.push({
                tag: '',
                tagDrawer: false,
                tagRadio: '',
            })
        },
        rtDelTag(arr) {
            arr.pop()
        },
        clearRtItemTag(item) {
            item.tag = null
        },
        tagRtItemDrawerOK(item) {
            item.tag = this.tagSelectObj.pointName
            item.tagDrawer = false
        },
        // 监听选择数据类型 
        changeTextDataType(e) {
            this.selectCell.setData({
                graphical: {
                    textItems: this.drawerNode.textItems,
                }
            })
        },
        // 监听选择运算符
        changeTextFormulaType(e) {
            this.selectCell.setData({
                graphical: {
                    textItems: this.drawerNode.textItems,
                }
            })
        },
        // 监听输入单位
        changeTextUnit(e) {
            this.selectCell.setData({
                graphical: {
                    textItems: this.drawerNode.textItems,
                }
            })
        },
        // 监听输入描述
        changeTextDesc(e) {
            this.selectCell.setData({
                graphical: {
                    textItems: this.drawerNode.textItems,
                }
            })
        },
        // 监听输入编码
        changeTextCode(e) {
            this.selectCell.setData({
                graphical: {
                    textItems: this.drawerNode.textItems,
                }
            })
        },
        // 监听输入含义
        changeTextMean(e) {
            this.selectCell.setData({
                graphical: {
                    textItems: this.drawerNode.textItems,
                }
            })
        },
        // 添加文本类型项
        addTextItems() {
            this.drawerNode.textItems.push({
                dataType: "",
                boolData: {
                    formulaType: 'empty',
                    tag: "",
                    unit: "",
                    tagDescribe: "",
                    values: [{
                        code:'',
                        meaning:''
                    }],
                    boolTagData: [{
                        tag: '',
                        tagDrawer: false,
                        tagRadio: '',
                    },
                    {
                        tag: '',
                        tagDrawer: false,
                        tagRadio: '',
                    }],
                },
                realData: {
                    formulaType: 'empty',
                    tag: "",
                    unit: "",
                    tagDescribe: "",
                    realTagData: [{
                        tag: '',
                        tagDrawer: false,
                        tagRadio: '',
                    },
                    {
                        tag: '',
                        tagDrawer: false,
                        tagRadio: '',
                    }],
                },
                textItemsDrawer: false,
                textItemsRadio: '',
            })
        },
        // 删除文本类型项
        delTextItems() {
            this.drawerNode.textItems.pop()
        },
        // 添加Bool类型的含义
        addTagExplain(arr) {
            arr.push({
                code:'',
                meaning:''
            })
        },
        // 删除Bool类型的含义
        delTagExplain(arr) {
            arr.pop()
        },
        // [文本]循环Tag清除
        clearTextItemTag(item) {
            if(item.boolData) {
                item.boolData.tag = null
            }
            if(item.realData) {
                item.realData.tag = null
            }
            this.$forceUpdate()
        },
        // [文本]循环Tag选择
        selectTextItemTag(item) {
            item.textItemsDrawer = true
        },
        // [文本]循环Tag确认
        tagTextItemDrawerOK(item) {
            if(item.boolData) {
                item.boolData.tag = this.tagSelectObj.pointName
                item.boolData.tagDescribe = this.tagSelectObj.describe
            }
            if(item.realData) {
                item.realData.tag = this.tagSelectObj.pointName
                item.realData.tagDescribe = this.tagSelectObj.describe
            }
            item.textItemsDrawer = false
            this.selectCell.setData({
                graphical: {
                    textItems: this.drawerNode.textItems,
                }
            })
            console.log(this.selectCell)
        },
    }
}
</script>
<style lang="less" scoped>
    @import '../../../../../css/iotConfigManage/rightDrawer.less';
</style>
