/**
 * 导出组态数据
 */
export const exportConfig = (graph) => {
    let graphToJSON = graph.toJSON()
    let cells = graphToJSON.cells
    cells.forEach(v => {
        let d = v.data
        let customAnimations = []
        let graphical = {}
        let textItems = []
        // 处理动效数据结构
        if (v.data) {
            // 动画
            if (d.graphical && d.graphical.customAnimations && d.graphical.customAnimations.length > 0) {
                for (let v in d.graphical.customAnimations) {
                    let tagData = d.graphical.customAnimations[v]
                    let animationObj = {
                        animation: tagData.animation,
                        withoutData: tagData.withoutData,
                    }
                    let boolData = {}
                    let realData = {}

                    // 无动画
                    if (tagData.EmptyAnimation.dataType == 'Bool') {//管道流水 Bool类型
                        let tag = ''
                        let tagArr = []
                        tagData.EmptyAnimation.boolData.boolTagData.forEach(btv => {
                            tagArr.push(btv.tag)
                        })
                        if (tagData.EmptyAnimation.boolData.formulaType == 'empty') {//Bool类型 运算符为 '无'
                            tag = tagData.EmptyAnimation.boolData.tag
                        }
                        if (tagData.EmptyAnimation.boolData.formulaType == '&') {//Bool类型 运算符为 '&'
                            tag = tagArr.join('&')
                        }
                        if (tagData.EmptyAnimation.boolData.formulaType == '||') {//Bool类型 运算符为 '||'
                            tag = tagArr.join('||')
                        }
                        if (tagData.EmptyAnimation.boolData.formulaType == '!') {//Bool类型 运算符为 '!'
                            tag = '!' + tagData.EmptyAnimation.boolData.tag
                        }
                        boolData = {
                            formulaType: tagData.EmptyAnimation.boolData.formulaType,
                            tag: tag,
                            tagValue: tagData.EmptyAnimation.boolData.tagValue,
                        }
                    }
                    if (tagData.EmptyAnimation.dataType == 'Real') {//管道流水 Real类型
                        let tag = ''
                        let tagArr = []
                        tagData.EmptyAnimation.realData.realTagData.forEach(rtv => {
                            tagArr.push(rtv.tag)
                        })
                        if (tagData.EmptyAnimation.realData.formulaType == 'empty') {//Real类型 运算符为 '无'
                            tag = tagData.EmptyAnimation.realData.tag
                        }
                        if (tagData.EmptyAnimation.realData.formulaType == '+') {//Real类型 运算符为 '+'
                            tag = tagArr.join('+')
                        }
                        if (tagData.EmptyAnimation.realData.formulaType == '-') {//Real类型 运算符为 '-'
                            tag = tagArr.join('-')
                        }
                        realData = {
                            formulaType: tagData.EmptyAnimation.realData.formulaType,
                            tag: tag,
                            unit: tagData.EmptyAnimation.realData.unit,
                        }
                    }
                    let emptyAnimation = {
                        dataType: tagData.EmptyAnimation.dataType,
                        boolData: boolData,
                        realData: realData,
                    }

                    // 管道流水动画 开始条件
                    if (tagData.startParameter.dataType == 'Bool') {//管道流水 Bool类型
                        let tag = ''
                        let tagArr = []
                        tagData.startParameter.boolData.boolTagData.forEach(btv => {
                            tagArr.push(btv.tag)
                        })
                        if (tagData.startParameter.boolData.formulaType == 'empty') {//Bool类型 运算符为 '无'
                            tag = tagData.startParameter.boolData.tag
                        }
                        if (tagData.startParameter.boolData.formulaType == '&') {//Bool类型 运算符为 '&'
                            tag = tagArr.join('&')
                        }
                        if (tagData.startParameter.boolData.formulaType == '||') {//Bool类型 运算符为 '||'
                            tag = tagArr.join('||')
                        }
                        if (tagData.startParameter.boolData.formulaType == '!') {//Bool类型 运算符为 '!'
                            tag = '!' + tagData.startParameter.boolData.tag
                        }
                        boolData = {
                            formulaType: tagData.startParameter.boolData.formulaType,
                            tag: tag,
                            tagValue: tagData.startParameter.boolData.tagValue,
                        }
                    }
                    if (tagData.startParameter.dataType == 'Real') {//管道流水 Real类型
                        let tag = ''
                        let tagArr = []
                        tagData.startParameter.realData.realTagData.forEach(rtv => {
                            tagArr.push(rtv.tag)
                        })
                        if (tagData.startParameter.realData.formulaType == 'empty') {//Real类型 运算符为 '无'
                            tag = tagData.startParameter.realData.tag
                        }
                        if (tagData.startParameter.realData.formulaType == '+') {//Real类型 运算符为 '+'
                            tag = tagArr.join('+')
                        }
                        if (tagData.startParameter.realData.formulaType == '-') {//Real类型 运算符为 '-'
                            tag = tagArr.join('-')
                        }
                        realData = {
                            formulaType: tagData.startParameter.realData.formulaType,
                            tag: tag,
                            unit: tagData.startParameter.realData.unit,
                        }
                    }
                    let startParameter = {
                        dataType: tagData.startParameter.dataType,
                        boolData: boolData,
                        realData: realData,
                    }

                    // 管道流水动画 结束条件
                    if (tagData.stopParameter.dataType == 'Bool') {//管道流水 Bool类型
                        let tag = ''
                        let tagArr = []
                        tagData.stopParameter.boolData.boolTagData.forEach(btv => {
                            tagArr.push(btv.tag)
                        })
                        if (tagData.stopParameter.boolData.formulaType == 'empty') {//Bool类型 运算符为 '无'
                            tag = tagData.stopParameter.boolData.tag
                        }
                        if (tagData.stopParameter.boolData.formulaType == '&') {//Bool类型 运算符为 '&'
                            tag = tagArr.join('&')
                        }
                        if (tagData.stopParameter.boolData.formulaType == '||') {//Bool类型 运算符为 '||'
                            tag = tagArr.join('||')
                        }
                        if (tagData.stopParameter.boolData.formulaType == '!') {//Bool类型 运算符为 '!'
                            tag = '!' + tagData.stopParameter.boolData.tag
                        }
                        boolData = {
                            formulaType: tagData.stopParameter.boolData.formulaType,
                            tag: tag,
                            tagValue: tagData.stopParameter.boolData.tagValue,
                        }
                    }
                    if (tagData.stopParameter.dataType == 'Real') {//管道流水 Real类型
                        let tag = ''
                        let tagArr = []
                        tagData.stopParameter.realData.realTagData.forEach(rtv => {
                            tagArr.push(rtv.tag)
                        })
                        if (tagData.stopParameter.realData.formulaType == 'empty') {//Real类型 运算符为 '无'
                            tag = tagData.stopParameter.realData.tag
                        }
                        if (tagData.stopParameter.realData.formulaType == '+') {//Real类型 运算符为 '+'
                            tag = tagArr.join('+')
                        }
                        if (tagData.stopParameter.realData.formulaType == '-') {//Real类型 运算符为 '-'
                            tag = tagArr.join('-')
                        }
                        realData = {
                            formulaType: tagData.stopParameter.realData.formulaType,
                            tag: tag,
                            unit: tagData.stopParameter.realData.unit,
                        }
                    }
                    let stopParameter = {
                        dataType: tagData.stopParameter.dataType,
                        boolData: boolData,
                        realData: realData,
                        display: tagData.stopParameter.display,
                    }

                    // 文本变化
                    let textChange = []
                    tagData.textChange.forEach(tc => {
                        if (tc.dataType = 'Bool') {//文本变化为Bool类型
                            let tag = ''
                            let tagArr = []
                            tc.boolData.boolTagData.forEach(btv => {
                                tagArr.push(btv.tag)
                            })
                            if (tc.boolData.formulaType == 'empty') {//Bool类型 运算符为 '无'
                                tag = tc.boolData.tag
                            }
                            if (tc.boolData.formulaType == '&') {//Bool类型 运算符为 '&'
                                tag = tagArr.join('&')
                            }
                            if (tc.boolData.formulaType == '||') {//Bool类型 运算符为 '||'
                                tag = tagArr.join('||')
                            }
                            if (tc.boolData.formulaType == '!') {//Bool类型 运算符为 '!'
                                tag = '!' + tc.boolData.tag
                            }
                            textChange.push({
                                dataType: tc.dataType,
                                boolData: {
                                    formulaType: tc.boolData.formulaType,
                                    tag: tag,
                                    tagValue: tc.boolData.tagValue,
                                    tagValueDescribe: tc.boolData.tagValueDescribe,
                                },
                                textColor: tc.textColor,
                                display: tc.display
                            })
                            return
                        }
                        if (tc.dataType = 'Real') {//文本变化为Real类型
                            let tag = ''
                            let tagArr = []
                            tc.realData.realTagData.forEach(btv => {
                                tagArr.push(btv.tag)
                            })
                            if (tc.realData.formulaType == 'empty') {//Real类型 运算符为 '无'
                                tag = tc.realData.tag
                            }
                            if (tc.realData.formulaType == '+') {//Real类型 运算符为 '+'
                                tag = tagArr.join('+')
                            }
                            if (tc.realData.formulaType == '-') {//Real类型 运算符为 '-'
                                tag = tagArr.join('-')
                            }
                            textChange.push({
                                dataType: tc.dataType,
                                realData: {
                                    formulaType: tc.realData.formulaType,
                                    tag: tag,
                                    unit: tc.realData.unit,
                                },
                                textColor: tc.textColor,
                                display: tc.display
                            })
                            return
                        }
                    })

                    // 背景变化
                    let background = []
                    tagData.background.forEach(tc => {
                        if (tc.dataType = 'Bool') {//文本变化为Bool类型
                            let tag = ''
                            let tagArr = []
                            let backgroundFilePath = null
                            let backgroundColor = null
                            tc.boolData.boolTagData.forEach(btv => {
                                tagArr.push(btv.tag)
                            })
                            if (tc.boolData.formulaType == 'empty') {//Bool类型 运算符为 '无'
                                tag = tc.boolData.tag
                            }
                            if (tc.boolData.formulaType == '&') {//Bool类型 运算符为 '&'
                                tag = tagArr.join('&')
                            }
                            if (tc.boolData.formulaType == '||') {//Bool类型 运算符为 '||'
                                tag = tagArr.join('||')
                            }
                            if (tc.boolData.formulaType == '!') {//Bool类型 运算符为 '!'
                                tag = '!' + tc.boolData.tag
                            }
                            if (tc.backgroundType == 'file') {
                                backgroundFilePath = tc.backgroundFilePath
                            } else if (tc.backgroundType == 'color') {
                                backgroundColor = tc.backgroundColor
                            }
                            background.push({
                                dataType: tc.dataType,
                                boolData: {
                                    formulaType: tc.boolData.formulaType,
                                    tag: tag,
                                    tagValue: tc.boolData.tagValue,
                                    tagValueDescribe: tc.boolData.tagValueDescribe,
                                },
                                backgroundType: tc.backgroundType,
                                backgroundFilePath: backgroundFilePath,
                                backgroundColor: backgroundColor
                            })
                            return
                        }
                        if (tc.dataType = 'Real') {//文本变化为Real类型
                            let tag = ''
                            let tagArr = []
                            let backgroundFilePath = null
                            let backgroundColor = null
                            tc.realData.realTagData.forEach(btv => {
                                tagArr.push(btv.tag)
                            })
                            if (tc.realData.formulaType == 'empty') {//Real类型 运算符为 '无'
                                tag = tc.realData.tag
                            }
                            if (tc.realData.formulaType == '+') {//Real类型 运算符为 '+'
                                tag = tagArr.join('+')
                            }
                            if (tc.realData.formulaType == '-') {//Real类型 运算符为 '-'
                                tag = tagArr.join('-')
                            }
                            if (tc.backgroundType == 'file') {
                                backgroundFilePath = tc.backgroundFilePath
                            } else if (tc.backgroundType == 'color') {
                                backgroundColor = tc.backgroundColor
                            }
                            background.push({
                                dataType: tc.dataType,
                                realData: {
                                    formulaType: tc.realData.formulaType,
                                    tag: tag,
                                    unit: tc.realData.unit,
                                },
                                backgroundType: tc.backgroundType,
                                backgroundFilePath: backgroundFilePath,
                                backgroundColor: backgroundColor
                            })
                            return
                        }
                    })

                    // 旋转动画
                    let rotateStartParameter = {}
                    let rotateStopParameter = {}
                    if(tagData.imgRotate.startParameter) {
                        // 旋转动画 开始条件
                        if (tagData.imgRotate.startParameter.dataType == 'Bool') {//旋转动画 Bool类型
                            let tag = ''
                            let tagArr = []
                            tagData.imgRotate.startParameter.boolData.boolTagData.forEach(btv => {
                                tagArr.push(btv.tag)
                            })
                            if (tagData.imgRotate.startParameter.boolData.formulaType == 'empty') {//Bool类型 运算符为 '无'
                                tag = tagData.imgRotate.startParameter.boolData.tag
                            }
                            if (tagData.imgRotate.startParameter.boolData.formulaType == '&') {//Bool类型 运算符为 '&'
                                tag = tagArr.join('&')
                            }
                            if (tagData.imgRotate.startParameter.boolData.formulaType == '||') {//Bool类型 运算符为 '||'
                                tag = tagArr.join('||')
                            }
                            if (tagData.imgRotate.startParameter.boolData.formulaType == '!') {//Bool类型 运算符为 '!'
                                tag = '!' + tagData.imgRotate.startParameter.boolData.tag
                            }
                            boolData = {
                                formulaType: tagData.imgRotate.startParameter.boolData.formulaType,
                                tag: tag,
                                tagValue: tagData.imgRotate.startParameter.boolData.tagValue,
                            }
                        }
                        if (tagData.imgRotate.startParameter.dataType == 'Real') {//旋转动画 Real类型
                            let tag = ''
                            let tagArr = []
                            tagData.imgRotate.startParameter.realData.realTagData.forEach(rtv => {
                                tagArr.push(rtv.tag)
                            })
                            if (tagData.imgRotate.startParameter.realData.formulaType == 'empty') {//Real类型 运算符为 '无'
                                tag = tagData.imgRotate.startParameter.realData.tag
                            }
                            if (tagData.imgRotate.startParameter.realData.formulaType == '+') {//Real类型 运算符为 '+'
                                tag = tagArr.join('+')
                            }
                            if (tagData.imgRotate.startParameter.realData.formulaType == '-') {//Real类型 运算符为 '-'
                                tag = tagArr.join('-')
                            }
                            realData = {
                                formulaType: tagData.imgRotate.startParameter.realData.formulaType,
                                tag: tag,
                                unit: tagData.imgRotate.startParameter.realData.unit,
                            }
                        }
                        rotateStartParameter = {
                            dataType: tagData.imgRotate.startParameter.dataType,
                            boolData: boolData,
                            realData: realData,
                        }

                        // 旋转动画 结束条件
                        if (tagData.imgRotate.stopParameter.dataType == 'Bool') {//旋转动画 Bool类型
                            let tag = ''
                            let tagArr = []
                            tagData.imgRotate.stopParameter.boolData.boolTagData.forEach(btv => {
                                tagArr.push(btv.tag)
                            })
                            if (tagData.imgRotate.stopParameter.boolData.formulaType == 'empty') {//Bool类型 运算符为 '无'
                                tag = tagData.imgRotate.stopParameter.boolData.tag
                            }
                            if (tagData.imgRotate.stopParameter.boolData.formulaType == '&') {//Bool类型 运算符为 '&'
                                tag = tagArr.join('&')
                            }
                            if (tagData.imgRotate.stopParameter.boolData.formulaType == '||') {//Bool类型 运算符为 '||'
                                tag = tagArr.join('||')
                            }
                            if (tagData.imgRotate.stopParameter.boolData.formulaType == '!') {//Bool类型 运算符为 '!'
                                tag = '!' + tagData.imgRotate.stopParameter.boolData.tag
                            }
                            boolData = {
                                formulaType: tagData.imgRotate.stopParameter.boolData.formulaType,
                                tag: tag,
                                tagValue: tagData.imgRotate.stopParameter.boolData.tagValue,
                            }
                        }
                        if (tagData.imgRotate.stopParameter.dataType == 'Real') {//旋转动画 Real类型
                            let tag = ''
                            let tagArr = []
                            tagData.imgRotate.stopParameter.realData.realTagData.forEach(rtv => {
                                tagArr.push(rtv.tag)
                            })
                            if (tagData.imgRotate.stopParameter.realData.formulaType == 'empty') {//Real类型 运算符为 '无'
                                tag = tagData.imgRotate.stopParameter.realData.tag
                            }
                            if (tagData.imgRotate.stopParameter.realData.formulaType == '+') {//Real类型 运算符为 '+'
                                tag = tagArr.join('+')
                            }
                            if (tagData.imgRotate.stopParameter.realData.formulaType == '-') {//Real类型 运算符为 '-'
                                tag = tagArr.join('-')
                            }
                            realData = {
                                formulaType: tagData.imgRotate.stopParameter.realData.formulaType,
                                tag: tag,
                                unit: tagData.imgRotate.stopParameter.realData.unit,
                            }
                        }
                        rotateStopParameter = {
                            dataType: tagData.imgRotate.stopParameter.dataType,
                            boolData: boolData,
                            realData: realData,
                            display: tagData.imgRotate.stopParameter.display,
                        }
                    }
                    
                    if (tagData.animation == 'Empty') {//无动画
                        animationObj.emptyAnimation = emptyAnimation
                    }
                    if (tagData.animation == 'PipelineFlow') {//管道流水
                        animationObj.pipelineFlow = {
                            startParameter: startParameter,
                            stopParameter: stopParameter,
                            animationRate: tagData.animationRate
                        }
                    }
                    if (tagData.animation == 'waterLevel') {//液位
                        animationObj.waterLevel = {
                            tag: tagData.tagName,
                            max: tagData.waterLevel.max,
                            defaultValue: tagData.waterLevel.defaultValue,
                        }
                    }
                    if (tagData.animation == 'textChange') {//文本变化
                        animationObj.textChange = {
                            dataParameters: textChange
                        }
                    }
                    if (tagData.animation == 'background') {//背景变化
                        animationObj.background = {
                            dataParameters: background
                        }
                    }
                    if (tagData.animation == 'rotate') {//旋转
                        animationObj.imgRotate = {
                            startParameter: rotateStartParameter,
                            stopParameter: rotateStopParameter,
                            animationRate: tagData.animationRate
                        }
                    }
                    customAnimations.push(animationObj)
                }
            }
            // 文本
            if (d.graphical && d.graphical.textItems && d.graphical.textItems.length > 0) {
                d.graphical.textItems.forEach(v => {
                    if (v.dataType == 'Bool') {//Bool类型文本
                        let tag = ''
                        let tagArr = []
                        let values = {}
                        v.boolData.boolTagData.forEach(btv => {
                            tagArr.push(btv.tag)
                        })
                        if (v.boolData.formulaType == 'empty') {//Bool类型 运算符为 '无'
                            tag = v.boolData.tag
                        }
                        if (v.boolData.formulaType == '&') {//Bool类型 运算符为 '&'
                            tag = tagArr.join('&')
                        }
                        if (v.boolData.formulaType == '||') {//Bool类型 运算符为 '||'
                            tag = tagArr.join('||')
                        }
                        if (v.boolData.formulaType == '!') {//Bool类型 运算符为 '!'
                            tag = '!' + v.boolData.tag
                        }

                        v.boolData.values.forEach(i => {
                            values[i.code] = i.meaning
                        })
                        let boolDataContent = {
                            formulaType: v.boolData.formulaType,
                            tag: tag,
                            tagDescribe: v.boolData.tagDescribe,
                            unit: v.boolData.unit,
                            values: values,
                        }
                        textItems.push({
                            dataType: v.dataType,
                            boolDataContent: boolDataContent
                        })
                    } else {//Real类型文本
                        let tag = ''
                        let tagArr = []
                        v.realData.realTagData.forEach(btv => {
                            tagArr.push(btv.tag)
                        })
                        if (v.realData.formulaType == 'empty') {//Real类型 运算符为 '无'
                            tag = v.realData.tag
                        }
                        if (v.realData.formulaType == '+') {//Real类型 运算符为 '+'
                            tag = tagArr.join('+')
                        }
                        if (v.realData.formulaType == '-') {//Real类型 运算符为 '-'
                            tag = tagArr.join('-')
                        }

                        let realDataContent = {
                            formulaType: v.realData.formulaType,
                            tag: tag,
                            tagDescribe: v.realData.tagDescribe,
                            unit: v.realData.unit,
                        }
                        textItems.push({
                            dataType: v.dataType,
                            realDataContent: realDataContent
                        })
                    }
                })
            }

            // 不同图形类别间参数做区分
            if (d.graphicalType == "button") {//图形类别为 button
                if (d.graphical.action == 0) {//button->按钮
                    graphical = {
                        action: d.graphical.action,
                        name: d.graphical.name,
                        downTag: d.graphical.downTag,
                        downTagValue: d.graphical.downTagValue,
                        upTag: d.graphical.upTag,
                        upTagValue: d.graphical.upTagValue,
                    }
                }
                if (d.graphical.action == 1) {//button->开关
                    graphical = {
                        action: d.graphical.action,
                        name: d.graphical.name,
                        pushTag: d.graphical.pushTag,
                        pushTagValue: d.graphical.pushTagValue,
                    }
                }
                if (d.graphical.action == 2) {//button->外链
                    graphical = {
                        action: d.graphical.action,
                        name: d.graphical.name,
                        configurationIdOutsideLink: d.graphical.configurationIdOutsideLink,
                    }
                }
                if (d.graphical.action == 3) {//button->历史记录
                    graphical = {
                        action: d.graphical.action,
                        name: d.graphical.name,
                    }
                }
                if (d.graphical.action == 4) {//button->告警
                    graphical = {
                        action: d.graphical.action,
                        name: d.graphical.name,
                    }
                }
            }
            if (d.graphicalType == "dynamicPicture") {//图形类别为 dynamicPicture
                graphical = {
                    name: d.graphical.name,
                    animationType: d.graphical.animationType,
                    customAnimations: customAnimations,
                }
            }
            if (d.graphicalType == "staticPicture") {//图形类别为 staticPicture
                graphical = {
                    name: d.graphical.name,
                }
            }
            if (d.graphicalType == "text") {//图形类别为 text
                graphical = {
                    name: d.graphical.name,
                    textItems: textItems,
                }
            }
            if(d.graphicalType == "editText") {//图形类别为 editText
                graphical = {
                    name: d.graphical.name,
                    max: d.graphical.max,
                    min: d.graphical.min,
                    pointBehindNum: d.graphical.pointBehindNum,
                    unit: d.graphical.unit,
                    tag: d.graphical.tag,
                    tagDescribe: d.graphical.tagDescribe,
                }
            }
            let formData = {
                eid: d.eid,
                gatewayEid: d.gatewayEid,
                graphical: graphical,
                graphicalType: d.graphicalType,
                withData: d.withData,
            }
            v.data = formData
        }
    })
    console.log(graphToJSON)

    sessionStorage.setItem('configCellsData', JSON.stringify(graphToJSON))
}