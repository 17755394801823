<template>
    <div>
        <el-form-item label="行为类别">
            <el-select size="small" v-model="drawerNode.behavior" placeholder="请选择行为类别" @change="changeBehavior">
                <el-option
                    v-for="item in behaviors"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"></el-option>
            </el-select>
        </el-form-item>
        <div v-show="drawerNode.behavior==0">
            <el-form-item label="鼠标按下">
                <div class="flex-change">
                    <el-button v-if="!drawerNode.boolTagDown" size="small" class="lube-btn" type="primary" @click.prevent="selectTag('mousedown')">选择Tag</el-button>
                    <span v-else class="close-lube-btn">
                        {{drawerNode.boolTagDown}}
                        <span class="el-icon-error close-lube" @click="clearTag('mousedown')"></span>
                    </span>
                    <el-input size="small" v-model="drawerNode.buttonTextDown" @change="changebuttonTextDown" placeholder="请输入Tag值"></el-input>
                </div>
            </el-form-item>
            <el-form-item label="鼠标抬起">
                <div class="flex-change">
                    <el-button v-if="!drawerNode.boolTagUp" size="small" class="lube-btn" type="primary" @click.prevent="selectTag('mouseup')">选择Tag</el-button>
                    <span v-else class="close-lube-btn">
                        {{drawerNode.boolTagUp}}
                        <span class="el-icon-error close-lube" @click="clearTag('mouseup')"></span>
                    </span>
                    <el-input size="small" v-model="drawerNode.buttonTextUp" @change="changebuttonTextUp" placeholder="请输入Tag值"></el-input>
                </div>
            </el-form-item>
        </div>
        <div v-show="drawerNode.behavior==1">
            <el-form-item label="发送指令">
                <div class="flex-change">
                    <el-button v-if="!drawerNode.sendInstructionTag" size="small" class="lube-btn" type="primary" @click.prevent="selectTag('instruction')">选择Tag</el-button>
                    <span v-else class="close-lube-btn">
                        {{drawerNode.sendInstructionTag}}
                        <span class="el-icon-error close-lube" @click="clearTag('instruction')"></span>
                    </span>
                    <el-input size="small" v-model="drawerNode.switchTextDown" @change="changeswitchTextDown" placeholder="请输入Tag值"></el-input>
                </div>
            </el-form-item>
        </div>
        <div v-show="drawerNode.behavior==2">
            <el-form-item label="选择组态">
                <div class="flex-change">
                    <el-input v-model="drawerNode.configurationIdOutsideLink" size="small" disabled placeholder="请选择组态"></el-input>
                    <el-button type="primary" size="small" @click="selectConfig">选择组态</el-button>
                </div>
            </el-form-item>
        </div>
        <div v-show="drawerNode.behavior==3"></div>
        <div v-show="drawerNode.behavior==4"></div>
        <!-- 侧滑选择Tag -->
        <el-drawer
            title="选择Tag"
            :with-header="false"
            :visible.sync="tagDrawer"
            direction="rtl"
            size="800px">
            <div class="drawer-title">选择Tag</div>
            <div class="drawer-content table-tree">
                <el-table :data="tagTableData" height="700">
                    <el-table-column label="勾选" width="70">
                        <template slot-scope="scope">
                        <el-radio-group v-model="tagRadio" @change="checkTagChange(scope.row)">
                            <el-radio :label="scope.row.id">{{''}}</el-radio>
                        </el-radio-group>
                        </template>
                    </el-table-column>
                    <el-table-column prop="number" label="序号" width="50"></el-table-column>
                    <el-table-column prop="dataType" label="数据类型"></el-table-column>
                    <el-table-column prop="pointName" label="点名"></el-table-column>
                    <el-table-column prop="describe" label="Tag描述" width="240"></el-table-column>
                    <el-table-column prop="readWriteType" label="读写方向"></el-table-column>
                    <el-table-column prop="cycle" label="采集周期"></el-table-column>
                </el-table>
            </div>
            <div class="drawer-bottom">
                <el-button size="small" type="primary" @click="tagDrawer=false">取消</el-button>
                <el-button
                size="small"
                type="primary"
                @click="tagDrawerOK"
                class="drawer-bottom-ok">确 定</el-button>
            </div>
        </el-drawer>
        <!-- 侧滑选择组态列表 -->
        <el-drawer
            title="选择组态"
            :with-header="false"
            :visible.sync="configDrawer"
            direction="rtl"
            size="800px">
            <div class="drawer-title">选择组态</div>
            <div class="drawer-content table-tree">
                <el-table :data="configTableData" height="700">
                    <el-table-column label="勾选" width="70">
                        <template slot-scope="scope">
                        <el-radio-group v-model="configRadio" @change="checkConfigChange(scope.row)">
                            <el-radio :label="scope.row.configurationId">{{''}}</el-radio>
                        </el-radio-group>
                        </template>
                    </el-table-column>
                    <el-table-column prop="name" label="名称"></el-table-column>
                    <el-table-column prop="describe" label="描述">
                        <template slot-scope="scope">
                            {{ scope.row.describe?scope.row.describe:'-' }}
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <div class="drawer-bottom">
                <el-button size="small" type="primary" @click="configDrawer=false">取消</el-button>
                <el-button
                size="small"
                type="primary"
                @click="configDrawerOK"
                class="drawer-bottom-ok">确 定</el-button>
            </div>
        </el-drawer>
    </div>
</template>
<script>
export default {
    data() {
        return {
            behaviors: [//按钮 -> 行为类别
                { id: 0, name: '按钮' },
                { id: 1, name: '开关' },
                { id: 2, name: '外链' },
                // { id: 3, name: '历史记录' },
                // { id: 4, name: '告警' },
            ],
            // 选择Tag模块
            tagDrawer: false,
            tagRadio: '',
            tagSelectObj: {},
            operation: null,
            // 选择组态列表
            configDrawer: false,
            configRadio: this.configRadios,
            configTableData: this.configTableDatas,
            configSelectObj: this.configSelectObjs,
        }
    },
    props: {
        selectCell:{
            type: String | Object
        },
        drawerNode: {
            type: Object
        },
        configRadios:{
            type: String
        },
        configTableDatas: {
            type: Array
        },
        configSelectObjs: {
            type: Object
        },
        tagTableData: {
            type: Array
        }
    },
    methods: {
        // 选择行为类别
        changeBehavior(val) {
            console.log(this.selectCell)
            console.log(val)
            if(val==0) {//配置按钮事件
                this.selectCell.store.data.event = 'node:mousedown,node:mouseup'
            }
            if(val==1) {//配置开关事件
                this.selectCell.store.data.event = 'node:click'
            }
            if(val==2) {
                this.selectCell.store.data.event = 'node:click'
            }
            if(val==3) {
                this.selectCell.store.data.event = 'node:click'
            }
            if(val==4) {
                this.selectCell.store.data.event = 'node:click'
            }
            this.selectCell.setData({
                graphical: {
                    action: val,
                }
            })  
        },
        // 鼠标按下tag对应的value
        changebuttonTextDown(val) {
            console.log(val)
            this.selectCell.setData({
                graphical: {
                    downTagValue: val,
                }
            })  
        },
        // 鼠标抬起tag对应的value
        changebuttonTextUp(val) {
            console.log(val)
            this.selectCell.setData({
                graphical: {
                    upTagValue: val,
                }
            })  
        },
        // 发送指令
        changeswitchTextDown(val) {
            console.log(val)
            this.selectCell.setData({
                graphical: {
                    pushTagValue: val,
                }
            })  
        },
        // 外链
        // 选择组态
        selectConfig() {
            this.configDrawer = true
        },
        // 勾选组态项
        checkConfigChange(row) {
            this.configSelectObj = row
        },
        // 点击OK确定选择组态项
        configDrawerOK() {
            this.drawerNode.configurationIdOutsideLink = this.configSelectObj.name
            this.selectCell.setData({
                graphical: {
                    configurationIdOutsideLink: this.configSelectObj.configurationId,
                }
            })  
            console.log(this.selectCell)
            this.configDrawer = false
        },
        
        // [按钮]清除Tag
        clearTag(operation) {
            if(operation=='mousedown') {
                this.drawerNode.boolTagDown = null
            }else if(operation=='mouseup') {
                this.drawerNode.boolTagUp = null
            }else if(operation=='instruction') {
                this.drawerNode.sendInstructionTag = null
            }
            this.$forceUpdate()
        },
        // [按钮]选择Tag
        selectTag(operation) {
            this.tagDrawer = true
            if(operation=='mousedown'){
                this.operation = 'mousedown'
            }
            if(operation=='mouseup') {
                this.operation = 'mouseup'
            }
            if(operation=='instruction') {
                this.operation = 'instruction'
            }
        },
        // 勾选Tag
        checkTagChange(row) {
            this.tagSelectObj = row
        },
        // [按钮]确认选择Tag
        tagDrawerOK() {
            if(this.operation=='mousedown') {
                this.drawerNode.boolTagDown = this.tagSelectObj.pointName
                this.selectCell.setData({
                    graphical: {
                        downTag: this.drawerNode.boolTagDown,
                    }
                })  
                console.log(this.drawerNode.boolTagDown)
            }else if(this.operation=='mouseup'){
                this.drawerNode.boolTagUp = this.tagSelectObj.pointName
                this.selectCell.setData({
                    graphical: {
                        upTag: this.drawerNode.boolTagUp,
                    }
                })  
                console.log(this.drawerNode.boolTagUp)
            }else if(this.operation=='instruction') {
                this.drawerNode.sendInstructionTag = this.tagSelectObj.pointName
                this.selectCell.setData({
                    graphical: {
                        pushTag: this.drawerNode.sendInstructionTag,
                    }
                })  
            }
            this.tagDrawer = false
        },
    }
}
</script>
<style lang="less" scoped>
    @import '../../../../../css/iotConfigManage/rightDrawer.less';
</style>
