<template>
    <div class="drawer_container">
        <div v-if="drawerType === 'grid'">
            <grid-setting :graph="graph" :grid="grid"></grid-setting>
        </div>
        <div v-if="drawerType === 'node'">
            <el-tabs v-model="nodeConfiguration" type="card">
                <el-tab-pane label="节点配置" name="first">
                    <div class="drawer_wrap">
                        <el-form label-width="80px" label-position="top">
                            <el-form-item label="关联数据">
                                <el-select size="small" v-model="drawerNode.withData" placeholder="请选择关联数据" @change="changeWithData">
                                    <el-option
                                        v-for="item in withData"
                                        :key="item.code"
                                        :label="item.name"
                                        :value="item.code"></el-option>
                                </el-select>
                            </el-form-item>
                            <div v-if="drawerNode.withData==0">
                                <el-form-item label="关联设备">
                                    <div class="flex-change">
                                        <el-input v-model="drawerNode.deviceName" size="small" disabled placeholder="请选择关联设备"></el-input>
                                        <el-button type="primary" size="small" @click="selectDevice">选择设备</el-button>
                                    </div>
                                </el-form-item>
                             </div>

                            <div>
                                <el-form-item label="图形类别">
                                    <el-select size="small" v-model="drawerNode.category" placeholder="请选择图形类别" @change="changeNodeType">
                                        <el-option
                                            v-for="item in categorys"
                                            :key="item.code"
                                            :label="item.name"
                                            :value="item.code"></el-option>
                                    </el-select>
                                </el-form-item>
                                <el-form-item label="节点名称">
                                    <el-input size="small" v-model="drawerNode.nodeText" @change="changeNodeText" placeholder="请输入节点名称"></el-input>
                                </el-form-item>
                                <!-- 图形类型为按钮 -->
                                <div v-show="drawerNode.category=='button'">
                                    <button-category :selectCell="selectCell" :drawerNode="drawerNode" :configRadios="configRadio" :configTableDatas="configTableData" :configSelectObjs="configSelectObj" :tagTableData="tagTableData"></button-category>
                                </div>
                                <!-- 图形类型为动态图片 -->
                                <div v-show="drawerNode.category=='dynamicPicture'">
                                    <dynamic-picture :selectCell="selectCell" :drawerNode="drawerNode" :tagTableData="tagTableData" :resourceData="resourceData"></dynamic-picture>
                                </div>
                                <!-- 图形类型为静态图片 -->
                                <div v-show="drawerNode.category=='staticPicture'"></div>
                                <!-- 图形类型为文本 -->
                                <div v-show="drawerNode.category=='text'">
                                    <text-category :selectCell="selectCell" :drawerNode="drawerNode" :tagTableData="tagTableData"></text-category>
                                </div>
                                <!-- 图形类型为编辑文本 -->
                                <div v-show="drawerNode.category=='editText'">
                                    <edit-text :selectCell="selectCell" :drawerNode="drawerNode" :tagTableData="tagTableData"></edit-text>
                                </div>
                            </div>
                        </el-form>
                    </div>
                </el-tab-pane>
                <el-tab-pane label="节点样式" name="second">
                    <div class="drawer_wrap">
                        <el-form label-width="80px" label-position="start">
                            <div class="nodestyle-text">
                                <div class="nodestyle-text-title">节点</div>
                                <div class="nodestyle-text-content">
                                    <el-form-item label="背景颜色" style="padding: 5px 0;">
                                        <el-color-picker :predefine="predefineColors" v-model="drawerNodeStyle.fill" size="small" show-alpha @change="changeFill" style="margin-top: 5px;"/>
                                    </el-form-item>
                                </div>
                            </div>
                            <div class="nodestyle-text">
                                <div class="nodestyle-text-title">文字</div>
                                <div class="nodestyle-text-content">
                                    <el-form-item label="文字大小" style="padding: 5px 0;">
                                        <el-slider v-model="drawerNodeStyle.fontSize" :min="10" :max="100" @change="changefontSize"></el-slider>
                                    </el-form-item>
                                    <el-form-item label="文字颜色" style="padding: 5px 0;">
                                        <el-color-picker :predefine="predefineColors" size="small" v-model="drawerNodeStyle.fontFill" show-alpha @change="changeFontFill" style="margin-top: 5px;"/>
                                    </el-form-item>
                                    <el-form-item label="文字字体" style="padding: 5px 0;">
                                        <el-select v-model="drawerNodeStyle.fontFamily" size="small" placeholder="请选择" @change="changeFontFamily" style="margin-top: 5px;">
                                            <el-option
                                                v-for="item in fontFamilyOptions"
                                                :key="item.value"
                                                :label="item.label"
                                                :value="item.value">
                                            </el-option>
                                        </el-select>
                                    </el-form-item>
                                    <el-form-item label="文字样式" style="padding: 5px 0;">
                                        <span class="nodestyle-text-style" :class="drawerNodeStyle.fontFlagWeight?'nodestyle-text-active':''" @click="changeFontWeight('bold')" style="margin-top: 5px;">
                                            <span v-show="!drawerNodeStyle.fontFlagWeight">B</span>
                                            <b v-show="drawerNodeStyle.fontFlagWeight">B</b>
                                        </span>
                                        <span class="nodestyle-text-style" :class="drawerNodeStyle.fontFlagStyle?'nodestyle-text-active':''" @click="changeFontStyle('italic')" style="margin-top: 5px;">
                                            <span v-show="!drawerNodeStyle.fontFlagStyle">Ⅰ</span>
                                            <i v-show="drawerNodeStyle.fontFlagStyle">Ⅰ</i>
                                        </span>
                                    </el-form-item>
                                </div>
                            </div>
                            <div class="nodestyle-text">
                                <div class="nodestyle-text-title">文字排版</div>
                                <div class="nodestyle-text-content">
                                    <el-form-item label="对齐方式" style="padding: 10px 0;">
                                        <el-radio-group size="mini" v-model="drawerNodeStyle.textJustifyFlag" @change="changeFontAlign" style="margin-top: 5px;">
                                            <el-radio-button label="start">左</el-radio-button>
                                            <el-radio-button label="middle">中</el-radio-button>
                                            <el-radio-button label="end">右</el-radio-button>
                                        </el-radio-group>
                                    </el-form-item>
                                    <el-form-item label="行间距" style="padding: 10px 0;">
                                        <el-select v-model="drawerNodeStyle.lineHeight" size="small" placeholder="请选择" @change="changeFontSpaceing" style="margin-top: 5px;">
                                            <el-option
                                                v-for="item in lineHeightOptions"
                                                :key="item.value"
                                                :label="item.label"
                                                :value="item.value">
                                            </el-option>
                                        </el-select>
                                    </el-form-item>
                                </div>
                            </div>
                            <div class="nodestyle-text">
                                <div class="nodestyle-text-title">边框</div>
                                <div class="nodestyle-text-content">
                                    <el-form-item label="边框大小" style="padding: 10px 0;">
                                        <el-slider v-model="drawerNodeStyle.strokeWidth" :min="0" :max="20" @change="changeStrokeWidth"></el-slider>
                                    </el-form-item>
                                    <el-form-item label="边框颜色" style="padding: 10px 0;">
                                        <el-color-picker :predefine="predefineColors" size="small" v-model="drawerNodeStyle.stroke" show-alpha @change="changeStroke" style="margin-top: 5px;"/>
                                    </el-form-item>
                                </div>
                            </div>
                            <div class="nodestyle-text">
                                <div class="nodestyle-text-title">动态文本颜色</div>
                                <div class="nodestyle-text-content">
                                    <el-form-item label="文本颜色" style="padding: 10px 0;">
                                        <el-color-picker :predefine="predefineColors" size="small" v-model="drawerNodeStyle.DynamicTextColor" show-alpha @change="changeDynamicTextColor" style="margin-top: 5px;"/>
                                    </el-form-item>
                                </div>
                            </div>
                        </el-form>
                    </div>
                </el-tab-pane>
            </el-tabs>
        </div>
        <div v-if="drawerType === 'edge'">
            <edge-setting :selectCell="selectCell" :drawerEdge="drawerEdge"></edge-setting>
        </div>
        <!-- 选择实体设备侧滑弹窗 -->
        <el-drawer
            title="选择设备"
            :with-header="false"
            :visible.sync="deviceDrawer"
            direction="rtl"
            size="1100px">
            <div class="drawer-title">选择设备</div>
            <div class="drawer-content table-tree">
            <div class="tree">
                <div class="tree-title">网关</div>
                <div class="tree-park" v-for="(item,index) in gatewayData" :key="index">
                    <div class="park-title" :class="current==item.eid?'bright':''" @click.prevent="gatewayToggle(item.eid)">
                        <span>{{item.equipmentName}}</span>
                    </div>
                </div>
            </div>
            <div class="table">
                <el-table :data="tableDataCp" height="700" v-loading="tableSubDeviceLoading" element-loading-spinner="el-icon-loading">
                <el-table-column label="勾选" width="70">
                    <template slot-scope="scope">
                    <el-radio-group v-model="deviceRadio" @change="checkDeviceChange(scope.row)">
                        <el-radio :label="scope.row.id">{{''}}</el-radio>
                    </el-radio-group>
                    </template>
                </el-table-column>
                <el-table-column prop="equipmentName" label="设备名称"></el-table-column>
                <el-table-column prop="eid" label="设备号"></el-table-column>
                </el-table>
            </div>
            </div>
            <div class="drawer-bottom">
            <el-button size="small" type="primary" @click="deviceDrawer=false">取消</el-button>
            <el-button
                size="small"
                type="primary"
                @click="deviceDrawerOK"
                class="drawer-bottom-ok">确 定</el-button>
            </div>
        </el-drawer>
    </div>
</template>

<script>
import GridSetting from './gridSetting/GridSetting';
import EdgeSetting from './edgeSetting/EdgeSetting';
import ButtonCategory from './nodeSetting/buttonCategory/ButtonCategory';
import DynamicPicture from './nodeSetting/dynamicPictureCategory/DynamicPicture';
import TextCategory from './nodeSetting/textCategory/TextCategory';
import EditText from './nodeSetting/editTextCategory/EditText';
export default {
    name:'RightDrawer',
    components: {
        GridSetting,
        EdgeSetting,
        ButtonCategory,
        DynamicPicture,
        TextCategory,
        EditText,
    },
    data() {
        return {
            nodeConfiguration: 'first',
            predefineColors: [
                '#ff0000',//50%
                '#ff8c00',
                '#ffd700',
                '#0ba618',
                '#1e90ff',
                '#6215c7',
                '#c71585',
                '#ff006a',
                '#ffffff',
                '#000000',

                '#ffe5e5',//95%
                '#fff3e5',
                '#fffbe5',
                '#e7fde9',
                '#e5f2ff',
                '#f1e8fd',
                '#fde8f5',
                '#ffe5f0',
                '#f2f2f2',
                '#a6a6a6',

                '#ffb3b3',//85%
                '#ffddb3',
                '#fff3b3',
                '#b7fabd',
                '#b3d9ff',
                '#d5baf8',
                '#f8bae1',
                '#ffb3d2',
                '#e6e6e6',
                '#8c8c8c',

                '#ff8080',//75%
                '#ffc680',
                '#ffeb80',
                '#87f791',
                '#80c0ff',
                '#b88cf3',
                '#f38ccd',
                '#ff80b5',
                '#d9d9d9',
                '#737373',

                '#ff4d4d',//65%
                '#ffaf4d',
                '#ffe34d',
                '#58f465',
                '#4da7ff',
                '#9c5eee',
                '#ee5eb8',
                '#ff4d97',
                '#cccccc',
                '#595959',

                '#ff1a1a',//55%
                '#ff981a',
                '#ffdb1a',
                '#28f139',
                '#0081ff',
                '#802fe9',
                '#e92fa4',
                '#ff1a79',
                '#bfbfbf',
                '#404040',
            ],
            fontFamilyOptions: [{
                value: 'Microsoft YaHei',
                label: '微软雅黑'
            }, {
                value: 'SimSun',
                label: '宋体'
            }, {
                value: 'SimHei',
                label: '黑体'
            }],
            lineHeightOptions: [{
                value: 20,
                label: '1.0倍'
            },{
                value: 30,
                label: '1.5倍'
            },{
                value: 40,
                label: '2.0倍'
            },{
                value: 50,
                label: '2.5倍'
            },{
                value: 60,
                label: '3.0倍'
            },{
                value: 70,
                label: '3.5倍'
            },],
            drawerNodeStyle: {
                fill:'',
                fontSize: 14,
                fontFill:'',
                strokeWidth: 1,
                stroke: '#000000',
                DynamicTextColor: '#eb3b5a',
                fontFamily: '',
                lineHeight: '',
                textJustifyFlag: '',
                fontFlagWeight: false,
                fontFlagStyle: false,
                fontWeight: '',
                fontStyle: ''
            },
            drawerNode:{
                withData: null,//关联设备
                deviceName: null,//设备名称
                category: null,//图形类别
                nodeText:'',//节点名称
                behavior: null,//图形类别为 [按钮] 的初始变量
                boolTagDown: null,
                buttonTextDown: null,
                boolTagUp: null,
                buttonTextUp: null,
                sendInstructionTag: null,
                switchTextDown: null,
                configurationIdOutsideLink: null,
                animationType: null,//图形类别为 [动态图片] 的初始变量
                customAnimations: [
                    {
                        animation: '',
                        withoutData: null,
                        startParameter: {
                            dataType: '',
                            boolData: {
                                formulaType: 'empty',
                                tag: '',
                                tagValue: '',
                                boolTagData: [
                                    {
                                        tag: '',
                                        tagDrawer: false,
                                        tagRadio: '',
                                    },
                                    {
                                        tag: '',
                                        tagDrawer: false,
                                        tagRadio: '',
                                    }
                                ]
                            },
                            realData: {
                                formulaType: 'empty',
                                tag: '',
                                unit: '',
                                realTagData: [
                                    {
                                        tag: '',
                                        tagDrawer: false,
                                        tagRadio: '',
                                    },
                                    {
                                        tag: '',
                                        tagDrawer: false,
                                        tagRadio: '',
                                    }
                                ]
                            },
                            tagDrawer: false,
                            tagRadio: '',
                        },
                        stopParameter: {
                            display: '',
                            dataType: '',
                            boolData: {
                                formulaType: 'empty',
                                tag: '',
                                tagValue: '',
                                boolTagData: [
                                    {
                                        tag: '',
                                        tagDrawer: false,
                                        tagRadio: '',
                                    },
                                    {
                                        tag: '',
                                        tagDrawer: false,
                                        tagRadio: '',
                                    }
                                ]
                            },
                            realData: {
                                formulaType: 'empty',
                                tag: '',
                                unit: '',
                                realTagData: [
                                    {
                                        tag: '',
                                        tagDrawer: false,
                                        tagRadio: '',
                                    },
                                    {
                                        tag: '',
                                        tagDrawer: false,
                                        tagRadio: '',
                                    }
                                ]
                            },
                            tagDrawer: false,
                            tagRadio: '',
                        },
                        EmptyAnimation: {
                            dataType: '',
                            boolData: {
                                formulaType: 'empty',
                                tag: '',
                                tagValue: '',
                                boolTagData: [
                                    {
                                        tag: '',
                                        tagDrawer: false,
                                        tagRadio: '',
                                    },
                                    {
                                        tag: '',
                                        tagDrawer: false,
                                        tagRadio: '',
                                    }
                                ]
                            },
                            realData: {
                                formulaType: 'empty',
                                tag: '',
                                unit: '',
                                realTagData: [
                                    {
                                        tag: '',
                                        tagDrawer: false,
                                        tagRadio: '',
                                    },
                                    {
                                        tag: '',
                                        tagDrawer: false,
                                        tagRadio: '',
                                    }
                                ]
                            },
                            tagDrawer: false,
                            tagRadio: '',
                        },
                        textChange: [
                            {
                                dataType: '',
                                boolData: {
                                    formulaType: 'empty',
                                    tag: '',
                                    tagValue: '',
                                    tagValueDescribe: '',
                                    boolTagData: [
                                        {
                                            tag: '',
                                            tagDrawer: false,
                                            tagRadio: '',
                                        },
                                        {
                                            tag: '',
                                            tagDrawer: false,
                                            tagRadio: '',
                                        }
                                    ]
                                },
                                realData: {
                                    formulaType: 'empty',
                                    tag: '',
                                    unit: '',
                                    realTagData: [
                                        {
                                            tag: '',
                                            tagDrawer: false,
                                            tagRadio: '',
                                        },
                                        {
                                            tag: '',
                                            tagDrawer: false,
                                            tagRadio: '',
                                        }
                                    ]
                                },
                                textColor: '',
                                display: '',
                                tagDrawer: false,
                                tagRadio: '',  
                            }
                        ],
                        background: [
                            {
                                dataType: '',
                                boolData: {
                                    formulaType: 'empty',
                                    tag: '',
                                    tagValue: '',
                                    tagValueDescribe: '',
                                    boolTagData: [
                                        {
                                            tag: '',
                                            tagDrawer: false,
                                            tagRadio: '',
                                        },
                                        {
                                            tag: '',
                                            tagDrawer: false,
                                            tagRadio: '',
                                        }
                                    ]
                                },
                                realData: {
                                    formulaType: 'empty',
                                    tag: '',
                                    unit: '',
                                    realTagData: [
                                        {
                                            tag: '',
                                            tagDrawer: false,
                                            tagRadio: '',
                                        },
                                        {
                                            tag: '',
                                            tagDrawer: false,
                                            tagRadio: '',
                                        }
                                    ]
                                },
                                backgroundType: '',
                                backgroundFilePath: '',
                                backgroundColor: '',
                                tagDrawer: false,
                                tagRadio: '',  
                                bgImgDrawer: false,
                                bgImgRadio: '',
                            }
                        ],
                        imgRotate: {
                            startParameter: {
                                dataType: '',
                                boolData: {
                                    formulaType: 'empty',
                                    tag: '',
                                    tagValue: '',
                                    boolTagData: [
                                        {
                                            tag: '',
                                            tagDrawer: false,
                                            tagRadio: '',
                                        },
                                        {
                                            tag: '',
                                            tagDrawer: false,
                                            tagRadio: '',
                                        }
                                    ]
                                },
                                realData: {
                                    formulaType: 'empty',
                                    tag: '',
                                    unit: '',
                                    realTagData: [
                                        {
                                            tag: '',
                                            tagDrawer: false,
                                            tagRadio: '',
                                        },
                                        {
                                            tag: '',
                                            tagDrawer: false,
                                            tagRadio: '',
                                        }
                                    ]
                                },
                                tagDrawer: false,
                                tagRadio: '',
                            },
                            stopParameter: {
                                display: '',
                                dataType: '',
                                boolData: {
                                    formulaType: 'empty',
                                    tag: '',
                                    tagValue: '',
                                    boolTagData: [
                                        {
                                            tag: '',
                                            tagDrawer: false,
                                            tagRadio: '',
                                        },
                                        {
                                            tag: '',
                                            tagDrawer: false,
                                            tagRadio: '',
                                        }
                                    ]
                                },
                                realData: {
                                    formulaType: 'empty',
                                    tag: '',
                                    unit: '',
                                    realTagData: [
                                        {
                                            tag: '',
                                            tagDrawer: false,
                                            tagRadio: '',
                                        },
                                        {
                                            tag: '',
                                            tagDrawer: false,
                                            tagRadio: '',
                                        }
                                    ]
                                },
                                tagDrawer: false,
                                tagRadio: '',
                            },
                        },
                        animationRate: {
                            rateType: null,
                            manualRate: null,
                            relativeTag: null,
                            relativeTagValue: null,
                        },
                        tagName: '',
                        tagValue: '',
                        waterLevel: {
                            max: '',
                            defaultValue: ''
                        },
                        tagDrawer: false,
                        tagRadio: '',
                    }
                ],
                textItems: [//图形类别为 [文本] 的初始变量
                    {
                        dataType: "",
                        boolData: {
                            formulaType: 'empty',
                            tag: "",
                            unit: "",
                            tagDescribe: "",
                            values: [{
                                code:'',
                                meaning:''
                            }],
                            boolTagData: [{
                                tag: '',
                                tagDrawer: false,
                                tagRadio: '',
                            },
                            {
                                tag: '',
                                tagDrawer: false,
                                tagRadio: '',
                            }],
                        },
                        realData: {
                            formulaType: 'empty',
                            tag: "",
                            unit: "",
                            tagDescribe: "",
                            realTagData: [{
                                tag: '',
                                tagDrawer: false,
                                tagRadio: '',
                            },
                            {
                                tag: '',
                                tagDrawer: false,
                                tagRadio: '',
                            }],
                        },
                        textItemsDrawer: false,
                        textItemsRadio: '',
                    }
                ],
                editTextMax: null,
                editTextMin: null,
                editTextPointBehindNum: null,
                editTextUnit: null,
                editTextTag: null,
                editTextTagDescribe: null,
            },
            drawerEdge:{
                EdgeText:'',
                edgeWidth:null,
                edgeColor:''
            },
            categorys: [//图形类别
                { code: 'button', name: '按钮' },
                { code: 'dynamicPicture', name: '动态图片' },
                { code: 'staticPicture', name: '静态图片' },
                { code: 'text', name: '文本' },
                { code: 'editText', name: '编辑文本' },
            ],
            withData: [//动画关联数据
                { code: 0, name: '关联' },
                { code: 1, name: '不关联' },
            ],
            
            // 选择设备模块
            current: '',
            equipmentBrandCode: '',
            gatewayData: [],
            deviceDrawer: false,
            tableSubDeviceLoading: false,//列表Loading
            deviceRadio: '',//radio高亮标识
            tableDataCp: [],//侧滑设备列表数组
            tableSelectList: [],//选择设备数组
            tableSelectArr: {},//选择设备数组
            // 选择Tag模块
            tagDrawer: false,
            tagRadio: '',
            tagTableData: [],
            tagSelectObj: {},
            // 选择组态列表
            configRadio: '',
            configTableData: [],
            configSelectObj: {}
        };
    },
    props:{
        drawerType: {
            type: String
        },
        selectCell:{
            type: String | Object
        },
        graph:{
            type: String | Object
        },
        grid:{
            type: Object
        },
        resourceData: {
            type: Array
        }
    },
    created() {
        
    },
    mounted() {
        this.loadGateway()
        this.loadListData()
    },
    watch:{
        selectCell:{
            handler(val) {
                if(val){
                    if(val.isNode()){ //节点
                        // console.log(val)
                        // 水流动画多种颜色回显
                        if(val.store.data.attrs.waterline1) {
                            this.drawerNodeStyle.fill = val.store.data.attrs.waterline1.stroke
                        }
                        // 液位动画多种颜色回显
                        if(val.store.data.attrs.levelRect2) {
                            this.drawerNodeStyle.fill = val.store.data.attrs.levelRect2.fill
                        }
                        if(val.store.data.attrs.body&&val.store.data.attrs.label) {
                            this.drawerNodeStyle.fill = val.store.data.attrs.body.fill
                            this.drawerNodeStyle.fontFill = val.store.data.attrs.label.fill
                            this.drawerNodeStyle.fontSize = Number(val.store.data.attrs.label.fontSize)
                            this.drawerNodeStyle.strokeWidth = Number(val.store.data.attrs.body.strokeWidth)
                            this.drawerNodeStyle.stroke = val.store.data.attrs.body.stroke

                            this.drawerNodeStyle.DynamicTextColor = val.store.data.attrs.label.DynamicTextColor
                            
                            this.drawerNodeStyle.fontFamily = val.store.data.attrs.label.fontFamily
                            this.drawerNodeStyle.lineHeight = val.store.data.attrs.label.lineHeight
                            this.drawerNodeStyle.textJustifyFlag = val.store.data.attrs.label.textAnchor
                            this.drawerNodeStyle.fontWeight = val.store.data.attrs.label.fontWeight
                            this.drawerNodeStyle.fontStyle = val.store.data.attrs.label.fontStyle
                            this.drawerNodeStyle.fontFlagWeight = false
                            this.drawerNodeStyle.fontFlagStyle = false
                            if(val.store.data.attrs.fontFlagWeight) {
                                this.drawerNodeStyle.fontFlagWeight = val.store.data.attrs.fontFlagWeight.flag
                            }
                            if(val.store.data.attrs.fontFlagStyle) {
                                this.drawerNodeStyle.fontFlagStyle = val.store.data.attrs.fontFlagStyle.flag
                            }
                        }
                        if(val.store.data.attrs.label) {//label存在回显
                            this.drawerNode.withData = val.store.data.attrs.label.withData
                            this.drawerNode.deviceName = val.store.data.attrs.label.deviceName
                            this.drawerNode.nodeText = val.store.data.attrs.label.text
                            if(val.store.data.data) {// data存在回显
                                if(this.drawerNode.withData==0) {
                                    this.current = val.store.data.data.gatewayEid
                                    this.loadEquipment()
                                    setTimeout(()=>{
                                        this.tableDataCp.forEach(v=>{
                                            if(v.eid==val.store.data.data.eid) {
                                                this.drawerNode.deviceName = v.equipmentName
                                                this.deviceRadio = v.id
                                                this.tableSelectArr = v
                                                this.tableData = this.tableSelectArr
                                                this.loadTagsList()
                                            }
                                        })
                                    },400)
                                }
                                
                                this.drawerNode.category = val.store.data.data.graphicalType
                                this.drawerNode.behavior = val.store.data.data.graphical.action
                                this.drawerNode.boolTagDown = val.store.data.data.graphical.downTag
                                this.drawerNode.buttonTextDown = val.store.data.data.graphical.downTagValue
                                this.drawerNode.boolTagUp = val.store.data.data.graphical.upTag
                                this.drawerNode.buttonTextUp = val.store.data.data.graphical.upTagValue
                                this.drawerNode.sendInstructionTag = val.store.data.data.graphical.pushTag
                                this.drawerNode.switchTextDown = val.store.data.data.graphical.pushTagValue

                                this.configTableData.forEach(v=>{
                                    if(v.configurationId==val.store.data.data.graphical.configurationIdOutsideLink) {
                                        this.drawerNode.configurationIdOutsideLink = v.name
                                        this.configRadio = v.configurationId
                                        this.configSelectObj = v
                                    }
                                })

                                this.drawerNode.animationType = val.store.data.data.graphical.animationType
                                if(val.store.data.data.graphical.customAnimations) {
                                    this.drawerNode.customAnimations = val.store.data.data.graphical.customAnimations
                                }
                                if(val.store.data.data.graphical.textItems) {
                                    this.drawerNode.textItems = val.store.data.data.graphical.textItems
                                }

                                this.drawerNode.editTextMax = val.store.data.data.graphical.max
                                this.drawerNode.editTextMin = val.store.data.data.graphical.min
                                this.drawerNode.editTextPointBehindNum = val.store.data.data.graphical.pointBehindNum
                                this.drawerNode.editTextUnit = val.store.data.data.graphical.unit
                                this.drawerNode.editTextTag = val.store.data.data.graphical.tag
                                this.drawerNode.editTextTagDescribe = val.store.data.data.graphical.tagDescribe
                            }else {//data不存在清空
                                this.drawerNode = {
                                    withData: null,//关联设备
                                    deviceName: null,
                                    category: null,
                                    nodeText:'',
                                    behavior: null,
                                    boolTagDown: null,
                                    buttonTextDown: null,
                                    boolTagUp: null,
                                    buttonTextUp: null,
                                    sendInstructionTag: null,
                                    switchTextDown: null,
                                    configurationIdOutsideLink: null,
                                    animationType: null,
                                    customAnimations: [
                                        {
                                            animation: '',
                                            withoutData: null,
                                            startParameter: {
                                                dataType: '',
                                                boolData: {
                                                    formulaType: 'empty',
                                                    tag: '',
                                                    tagValue: '',
                                                    boolTagData: [
                                                        {
                                                            tag: '',
                                                            tagDrawer: false,
                                                            tagRadio: '',
                                                        },
                                                        {
                                                            tag: '',
                                                            tagDrawer: false,
                                                            tagRadio: '',
                                                        }
                                                    ]
                                                },
                                                realData: {
                                                    formulaType: 'empty',
                                                    tag: '',
                                                    unit: '',
                                                    realTagData: [
                                                        {
                                                            tag: '',
                                                            tagDrawer: false,
                                                            tagRadio: '',
                                                        },
                                                        {
                                                            tag: '',
                                                            tagDrawer: false,
                                                            tagRadio: '',
                                                        }
                                                    ]
                                                },
                                                tagDrawer: false,
                                                tagRadio: '',
                                            },
                                            stopParameter: {
                                                display: '',
                                                dataType: '',
                                                boolData: {
                                                    formulaType: 'empty',
                                                    tag: '',
                                                    tagValue: '',
                                                    boolTagData: [
                                                        {
                                                            tag: '',
                                                            tagDrawer: false,
                                                            tagRadio: '',
                                                        },
                                                        {
                                                            tag: '',
                                                            tagDrawer: false,
                                                            tagRadio: '',
                                                        }
                                                    ]
                                                },
                                                realData: {
                                                    formulaType: 'empty',
                                                    tag: '',
                                                    unit: '',
                                                    realTagData: [
                                                        {
                                                            tag: '',
                                                            tagDrawer: false,
                                                            tagRadio: '',
                                                        },
                                                        {
                                                            tag: '',
                                                            tagDrawer: false,
                                                            tagRadio: '',
                                                        }
                                                    ]
                                                },
                                                tagDrawer: false,
                                                tagRadio: '',
                                            },
                                            EmptyAnimation: {
                                                dataType: '',
                                                boolData: {
                                                    formulaType: 'empty',
                                                    tag: '',
                                                    tagValue: '',
                                                    boolTagData: [
                                                        {
                                                            tag: '',
                                                            tagDrawer: false,
                                                            tagRadio: '',
                                                        },
                                                        {
                                                            tag: '',
                                                            tagDrawer: false,
                                                            tagRadio: '',
                                                        }
                                                    ]
                                                },
                                                realData: {
                                                    formulaType: 'empty',
                                                    tag: '',
                                                    unit: '',
                                                    realTagData: [
                                                        {
                                                            tag: '',
                                                            tagDrawer: false,
                                                            tagRadio: '',
                                                        },
                                                        {
                                                            tag: '',
                                                            tagDrawer: false,
                                                            tagRadio: '',
                                                        }
                                                    ]
                                                },
                                                tagDrawer: false,
                                                tagRadio: '',
                                            },
                                            textChange: [
                                                {
                                                    dataType: '',
                                                    boolData: {
                                                        formulaType: 'empty',
                                                        tag: '',
                                                        tagValue: '',
                                                        tagValueDescribe: '',
                                                        boolTagData: [
                                                            {
                                                                tag: '',
                                                                tagDrawer: false,
                                                                tagRadio: '',
                                                            },
                                                            {
                                                                tag: '',
                                                                tagDrawer: false,
                                                                tagRadio: '',
                                                            }
                                                        ]
                                                    },
                                                    realData: {
                                                        formulaType: 'empty',
                                                        tag: '',
                                                        unit: '',
                                                        realTagData: [
                                                            {
                                                                tag: '',
                                                                tagDrawer: false,
                                                                tagRadio: '',
                                                            },
                                                            {
                                                                tag: '',
                                                                tagDrawer: false,
                                                                tagRadio: '',
                                                            }
                                                        ]
                                                    },
                                                    textColor: '',
                                                    display: '',
                                                    tagDrawer: false,
                                                    tagRadio: '',  
                                                }
                                            ],
                                            background: [
                                                {
                                                    dataType: '',
                                                    boolData: {
                                                        formulaType: 'empty',
                                                        tag: '',
                                                        tagValue: '',
                                                        tagValueDescribe: '',
                                                        boolTagData: [
                                                            {
                                                                tag: '',
                                                                tagDrawer: false,
                                                                tagRadio: '',
                                                            },
                                                            {
                                                                tag: '',
                                                                tagDrawer: false,
                                                                tagRadio: '',
                                                            }
                                                        ]
                                                    },
                                                    realData: {
                                                        formulaType: 'empty',
                                                        tag: '',
                                                        unit: '',
                                                        realTagData: [
                                                            {
                                                                tag: '',
                                                                tagDrawer: false,
                                                                tagRadio: '',
                                                            },
                                                            {
                                                                tag: '',
                                                                tagDrawer: false,
                                                                tagRadio: '',
                                                            }
                                                        ]
                                                    },
                                                    backgroundType: '',
                                                    backgroundFilePath: '',
                                                    backgroundColor: '',
                                                    tagDrawer: false,
                                                    tagRadio: '',  
                                                    bgImgDrawer: false,
                                                    bgImgRadio: '',
                                                }
                                            ],
                                            imgRotate: {
                                                startParameter: {
                                                    dataType: '',
                                                    boolData: {
                                                        formulaType: 'empty',
                                                        tag: '',
                                                        tagValue: '',
                                                        boolTagData: [
                                                            {
                                                                tag: '',
                                                                tagDrawer: false,
                                                                tagRadio: '',
                                                            },
                                                            {
                                                                tag: '',
                                                                tagDrawer: false,
                                                                tagRadio: '',
                                                            }
                                                        ]
                                                    },
                                                    realData: {
                                                        formulaType: 'empty',
                                                        tag: '',
                                                        unit: '',
                                                        realTagData: [
                                                            {
                                                                tag: '',
                                                                tagDrawer: false,
                                                                tagRadio: '',
                                                            },
                                                            {
                                                                tag: '',
                                                                tagDrawer: false,
                                                                tagRadio: '',
                                                            }
                                                        ]
                                                    },
                                                    tagDrawer: false,
                                                    tagRadio: '',
                                                },
                                                stopParameter: {
                                                    display: '',
                                                    dataType: '',
                                                    boolData: {
                                                        formulaType: 'empty',
                                                        tag: '',
                                                        tagValue: '',
                                                        boolTagData: [
                                                            {
                                                                tag: '',
                                                                tagDrawer: false,
                                                                tagRadio: '',
                                                            },
                                                            {
                                                                tag: '',
                                                                tagDrawer: false,
                                                                tagRadio: '',
                                                            }
                                                        ]
                                                    },
                                                    realData: {
                                                        formulaType: 'empty',
                                                        tag: '',
                                                        unit: '',
                                                        realTagData: [
                                                            {
                                                                tag: '',
                                                                tagDrawer: false,
                                                                tagRadio: '',
                                                            },
                                                            {
                                                                tag: '',
                                                                tagDrawer: false,
                                                                tagRadio: '',
                                                            }
                                                        ]
                                                    },
                                                    tagDrawer: false,
                                                    tagRadio: '',
                                                },
                                            },
                                            animationRate: {
                                                rateType: null,
                                                manualRate: null,
                                                relativeTag: null,
                                                relativeTagValue: null,
                                            },
                                            tagName: '',
                                            tagValue: '',
                                            waterLevel: {
                                                max: '',
                                                defaultValue: ''
                                            },
                                            tagDrawer: false,
                                            tagRadio: '',
                                        }
                                    ],
                                    textItems: [
                                        {
                                            dataType: "",
                                            boolData: {
                                                formulaType: 'empty',
                                                tag: "",
                                                unit: "",
                                                tagDescribe: "",
                                                values: [{
                                                    code:'',
                                                    meaning:''
                                                }],
                                                boolTagData: [{
                                                    tag: '',
                                                    tagDrawer: false,
                                                    tagRadio: '',
                                                },
                                                {
                                                    tag: '',
                                                    tagDrawer: false,
                                                    tagRadio: '',
                                                }],
                                            },
                                            realData: {
                                                formulaType: 'empty',
                                                tag: "",
                                                unit: "",
                                                tagDescribe: "",
                                                realTagData: [{
                                                    tag: '',
                                                    tagDrawer: false,
                                                    tagRadio: '',
                                                },
                                                {
                                                    tag: '',
                                                    tagDrawer: false,
                                                    tagRadio: '',
                                                }],
                                            },
                                            textItemsDrawer: false,
                                            textItemsRadio: '',
                                        }
                                    ],
                                    editTextMax: null,
                                    editTextMin: null,
                                    editTextPointBehindNum: null,
                                    editTextUnit: null,
                                    editTextTag: null,
                                    editTextTagDescribe: null,
                                }
                            }
                        }else {//label不存在清空
                            this.drawerNode = {
                                withData: null,//关联设备
                                deviceName: null,
                                category: null,
                                nodeText:'',
                                behavior: null,
                                boolTagDown: null,
                                buttonTextDown: null,
                                boolTagUp: null,
                                buttonTextUp: null,
                                sendInstructionTag: null,
                                switchTextDown: null,
                                configurationIdOutsideLink: null,
                                animationType: null,
                                customAnimations: [
                                    {
                                        animation: '',
                                        withoutData: null,
                                        startParameter: {
                                            dataType: '',
                                            boolData: {
                                                formulaType: 'empty',
                                                tag: '',
                                                tagValue: '',
                                                boolTagData: [
                                                    {
                                                        tag: '',
                                                        tagDrawer: false,
                                                        tagRadio: '',
                                                    },
                                                    {
                                                        tag: '',
                                                        tagDrawer: false,
                                                        tagRadio: '',
                                                    }
                                                ]
                                            },
                                            realData: {
                                                formulaType: 'empty',
                                                tag: '',
                                                unit: '',
                                                realTagData: [
                                                    {
                                                        tag: '',
                                                        tagDrawer: false,
                                                        tagRadio: '',
                                                    },
                                                    {
                                                        tag: '',
                                                        tagDrawer: false,
                                                        tagRadio: '',
                                                    }
                                                ]
                                            },
                                            tagDrawer: false,
                                            tagRadio: '',
                                        },
                                        stopParameter: {
                                            display: '',
                                            dataType: '',
                                            boolData: {
                                                formulaType: 'empty',
                                                tag: '',
                                                tagValue: '',
                                                boolTagData: [
                                                    {
                                                        tag: '',
                                                        tagDrawer: false,
                                                        tagRadio: '',
                                                    },
                                                    {
                                                        tag: '',
                                                        tagDrawer: false,
                                                        tagRadio: '',
                                                    }
                                                ]
                                            },
                                            realData: {
                                                formulaType: 'empty',
                                                tag: '',
                                                unit: '',
                                                realTagData: [
                                                    {
                                                        tag: '',
                                                        tagDrawer: false,
                                                        tagRadio: '',
                                                    },
                                                    {
                                                        tag: '',
                                                        tagDrawer: false,
                                                        tagRadio: '',
                                                    }
                                                ]
                                            },
                                            tagDrawer: false,
                                            tagRadio: '',
                                        },
                                        EmptyAnimation: {
                                            dataType: '',
                                            boolData: {
                                                formulaType: 'empty',
                                                tag: '',
                                                tagValue: '',
                                                boolTagData: [
                                                    {
                                                        tag: '',
                                                        tagDrawer: false,
                                                        tagRadio: '',
                                                    },
                                                    {
                                                        tag: '',
                                                        tagDrawer: false,
                                                        tagRadio: '',
                                                    }
                                                ]
                                            },
                                            realData: {
                                                formulaType: 'empty',
                                                tag: '',
                                                unit: '',
                                                realTagData: [
                                                    {
                                                        tag: '',
                                                        tagDrawer: false,
                                                        tagRadio: '',
                                                    },
                                                    {
                                                        tag: '',
                                                        tagDrawer: false,
                                                        tagRadio: '',
                                                    }
                                                ]
                                            },
                                            tagDrawer: false,
                                            tagRadio: '',
                                        },
                                        textChange: [
                                            {
                                                dataType: '',
                                                boolData: {
                                                    formulaType: 'empty',
                                                    tag: '',
                                                    tagValue: '',
                                                    tagValueDescribe: '',
                                                    boolTagData: [
                                                        {
                                                            tag: '',
                                                            tagDrawer: false,
                                                            tagRadio: '',
                                                        },
                                                        {
                                                            tag: '',
                                                            tagDrawer: false,
                                                            tagRadio: '',
                                                        }
                                                    ]
                                                },
                                                realData: {
                                                    formulaType: 'empty',
                                                    tag: '',
                                                    unit: '',
                                                    realTagData: [
                                                        {
                                                            tag: '',
                                                            tagDrawer: false,
                                                            tagRadio: '',
                                                        },
                                                        {
                                                            tag: '',
                                                            tagDrawer: false,
                                                            tagRadio: '',
                                                        }
                                                    ]
                                                },
                                                textColor: '',
                                                display: '',
                                                tagDrawer: false,
                                                tagRadio: '',  
                                            }
                                        ],
                                        background: [
                                            {
                                                dataType: '',
                                                boolData: {
                                                    formulaType: 'empty',
                                                    tag: '',
                                                    tagValue: '',
                                                    tagValueDescribe: '',
                                                    boolTagData: [
                                                        {
                                                            tag: '',
                                                            tagDrawer: false,
                                                            tagRadio: '',
                                                        },
                                                        {
                                                            tag: '',
                                                            tagDrawer: false,
                                                            tagRadio: '',
                                                        }
                                                    ]
                                                },
                                                realData: {
                                                    formulaType: 'empty',
                                                    tag: '',
                                                    unit: '',
                                                    realTagData: [
                                                        {
                                                            tag: '',
                                                            tagDrawer: false,
                                                            tagRadio: '',
                                                        },
                                                        {
                                                            tag: '',
                                                            tagDrawer: false,
                                                            tagRadio: '',
                                                        }
                                                    ]
                                                },
                                                backgroundType: '',
                                                backgroundFilePath: '',
                                                backgroundColor: '',
                                                tagDrawer: false,
                                                tagRadio: '',  
                                                bgImgDrawer: false,
                                                bgImgRadio: '',
                                            }
                                        ],
                                        imgRotate: {
                                            startParameter: {
                                                dataType: '',
                                                boolData: {
                                                    formulaType: 'empty',
                                                    tag: '',
                                                    tagValue: '',
                                                    boolTagData: [
                                                        {
                                                            tag: '',
                                                            tagDrawer: false,
                                                            tagRadio: '',
                                                        },
                                                        {
                                                            tag: '',
                                                            tagDrawer: false,
                                                            tagRadio: '',
                                                        }
                                                    ]
                                                },
                                                realData: {
                                                    formulaType: 'empty',
                                                    tag: '',
                                                    unit: '',
                                                    realTagData: [
                                                        {
                                                            tag: '',
                                                            tagDrawer: false,
                                                            tagRadio: '',
                                                        },
                                                        {
                                                            tag: '',
                                                            tagDrawer: false,
                                                            tagRadio: '',
                                                        }
                                                    ]
                                                },
                                                tagDrawer: false,
                                                tagRadio: '',
                                            },
                                            stopParameter: {
                                                display: '',
                                                dataType: '',
                                                boolData: {
                                                    formulaType: 'empty',
                                                    tag: '',
                                                    tagValue: '',
                                                    boolTagData: [
                                                        {
                                                            tag: '',
                                                            tagDrawer: false,
                                                            tagRadio: '',
                                                        },
                                                        {
                                                            tag: '',
                                                            tagDrawer: false,
                                                            tagRadio: '',
                                                        }
                                                    ]
                                                },
                                                realData: {
                                                    formulaType: 'empty',
                                                    tag: '',
                                                    unit: '',
                                                    realTagData: [
                                                        {
                                                            tag: '',
                                                            tagDrawer: false,
                                                            tagRadio: '',
                                                        },
                                                        {
                                                            tag: '',
                                                            tagDrawer: false,
                                                            tagRadio: '',
                                                        }
                                                    ]
                                                },
                                                tagDrawer: false,
                                                tagRadio: '',
                                            },
                                        },
                                        animationRate: {
                                            rateType: null,
                                            manualRate: null,
                                            relativeTag: null,
                                            relativeTagValue: null,
                                        },
                                        tagName: '',
                                        tagValue: '',
                                        waterLevel: {
                                            max: '',
                                            defaultValue: ''
                                        },
                                        tagDrawer: false,
                                        tagRadio: '',
                                    }
                                ],
                                textItems: [
                                    {
                                        dataType: "",
                                        boolData: {
                                            formulaType: 'empty',
                                            tag: "",
                                            unit: "",
                                            tagDescribe: "",
                                            values: [{
                                                code:'',
                                                meaning:''
                                            }],
                                            boolTagData: [{
                                                tag: '',
                                                tagDrawer: false,
                                                tagRadio: '',
                                            },
                                            {
                                                tag: '',
                                                tagDrawer: false,
                                                tagRadio: '',
                                            }],
                                        },
                                        realData: {
                                            formulaType: 'empty',
                                            tag: "",
                                            unit: "",
                                            tagDescribe: "",
                                            realTagData: [{
                                                tag: '',
                                                tagDrawer: false,
                                                tagRadio: '',
                                            },
                                            {
                                                tag: '',
                                                tagDrawer: false,
                                                tagRadio: '',
                                            }],
                                        },
                                        textItemsDrawer: false,
                                        textItemsRadio: '',
                                    }
                                ],
                                editTextMax: null,
                                editTextMin: null,
                                editTextPointBehindNum: null,
                                editTextUnit: null,
                                editTextTag: null,
                                editTextTagDescribe: null,
                            }
                        }
                    }else{ //边
                        this.drawerEdge.EdgeText = val.store.data.labels?val.store.data.labels[0].text:''
                        this.drawerEdge.edgeWidth = Number(val.store.data.attrs.line.strokeWidth)
                        this.drawerEdge.edgeColor = val.store.data.attrs.line.stroke
                    }
                }
            },
            immediate: true,
            deep: false
        },
    },
    methods: {
        /**                                                              选设备模块                                                          **/
        // 新增实体设备
        selectDevice() {
            this.deviceDrawer = true
        },
        // 切换网关
        gatewayToggle(eid) {
            this.current = eid
            this.loadEquipment()
        },
        // 获取网关
        loadGateway() {
            this.$ajax.post('deviceTypeByEquipment',{
                deviceTypeCode: 'Gateway',
                pageNum: 0,
                pageSize: 0
            }).then(res => {
                this.gatewayData = res.data
                this.current = res.data[0].eid
                this.loadEquipment()
            })
        },
        // 获取网关子设备
        loadEquipment() {
            this.tableSubDeviceLoading = true
            this.$ajax.post('subGetewayQuery',{
                eid: this.current,
            }).then(res => {
                res.data.forEach(item => {
                    item.checked = false
                })
                this.tableDataCp = res.data
                this.tableSubDeviceLoading = false
            })
        },
        // 勾选设备
        checkDeviceChange(row) {
            this.tableSelectArr = {}
            this.deviceRadio = row.id
            this.tableSelectArr = row
        },
        // 勾选完毕点击确定
        deviceDrawerOK() {
            this.tableData = this.tableSelectArr
            this.loadTagsList()
            if(this.tableData.eid) {
                this.selectCell.setData({
                    gatewayEid: this.current,
                    eid: this.tableData.eid,
                })
            }
            this.drawerNode.deviceName = this.tableData.equipmentName
            this.selectCell.attr('label/deviceName', this.drawerNode.deviceName)
            this.deviceDrawer = false
        },
        // 获取Tag列表
        loadTagsList() {
            this.$ajax.post("iotTagsQuery", {}, { 
                eid: this.tableData.eid
            }).then(res=>{
                this.tagTableData = res.data
            })
        },
        // 查询组态
        loadListData() {
            this.$ajax.post('configurationQuery', {
                pageNum: 0,
                pageSize: 0,
                projectId: sessionStorage.getItem('configProjectId'),
            }).then(res => {
                this.configTableData = res.data
            })
        },

        /**                                                              节点配置                                                          **/
        // 节点背景
        changeFill(val){
            this.drawerNodeStyle.fill = val
            // 水流动画多种颜色赋值
            if(this.selectCell.store.data.attrs.waterline1) {
                this.selectCell.attr('waterline1/stroke', val)
                this.selectCell.attr('waterline2/stroke', val)
            }
            // 液位动画多种颜色赋值
            if(this.selectCell.store.data.attrs.levelRect2) {
                this.selectCell.attr('levelRect2/fill', val)
            }
            this.selectCell.attr('body/fill', val)
        },
        // 节点字体大小
        changefontSize(val){
            this.selectCell.attr('label/fontSize',val)
        },
        // 节点字体颜色
        changeFontFill(val){
            this.drawerNodeStyle.fontFill = val
            this.selectCell.attr('label/fill', this.drawerNodeStyle.fontFill)
        },
        // 节点边框宽度
        changeStrokeWidth(val){
            this.selectCell.attr('body/strokeWidth', val)
        },
        // 节点边框颜色
        changeStroke(val){
            this.drawerNodeStyle.stroke = val
            this.selectCell.attr('body/stroke', this.drawerNodeStyle.stroke)
        },
        // 动态文本颜色
        changeDynamicTextColor(val) {
            this.drawerNodeStyle.DynamicTextColor = val
            this.selectCell.attr('label/DynamicTextColor', this.drawerNodeStyle.DynamicTextColor)
        },
        // 文字字体
        changeFontFamily(val) {
            this.drawerNodeStyle.fontFamily = val
            this.selectCell.attr('label/fontFamily', this.drawerNodeStyle.fontFamily)
        },
        // 文字间距
        changeFontSpaceing(val) {
            this.drawerNodeStyle.lineHeight = val
            this.selectCell.attr('label/lineHeight', this.drawerNodeStyle.lineHeight)
        },
        // 文字对齐方式
        changeFontAlign(alignType) {
            if(alignType=='start') {
                this.drawerNodeStyle.textJustifyFlag = alignType
                this.selectCell.attr('label/textAnchor', this.drawerNodeStyle.textJustifyFlag)
            }
            if(alignType=='middle') {
                this.drawerNodeStyle.textJustifyFlag = alignType
                this.selectCell.attr('label/textAnchor', this.drawerNodeStyle.textJustifyFlag)
            }
            if(alignType=='end') {
                this.drawerNodeStyle.textJustifyFlag = alignType
                this.selectCell.attr('label/textAnchor', this.drawerNodeStyle.textJustifyFlag)
            }
        },
        // 文字加粗
        changeFontWeight(bold) {
            this.drawerNodeStyle.fontFlagWeight = !this.drawerNodeStyle.fontFlagWeight
            if(this.drawerNodeStyle.fontFlagWeight) {
                this.drawerNodeStyle.fontWeight = bold
            }else {
                this.drawerNodeStyle.fontWeight = 'normal'
            }
            this.selectCell.attr('label/fontWeight', this.drawerNodeStyle.fontWeight)
            this.selectCell.attr('fontFlagWeight/flag', this.drawerNodeStyle.fontFlagWeight)
        },
        // 文字倾斜
        changeFontStyle(italic) {
            this.drawerNodeStyle.fontFlagStyle = !this.drawerNodeStyle.fontFlagStyle
            if(this.drawerNodeStyle.fontFlagStyle) {
                this.drawerNodeStyle.fontStyle = italic
            }else {
                this.drawerNodeStyle.fontStyle = 'normal'
            }
            this.selectCell.attr('label/fontStyle', this.drawerNodeStyle.fontStyle)
            this.selectCell.attr('fontFlagStyle/flag', this.drawerNodeStyle.fontFlagStyle)
        },
        /**                                                              公共项配置                                                          **/
        changeWithData(val) {
            this.selectCell.setData({
                withData: val
            })
            this.selectCell.attr('label/withData', this.drawerNode.withData)
        },
        // 选择图形类别
        changeNodeType(val) {
            this.selectCell.setData({
                graphicalType: val
            })       
        },
        // 节点名称
        changeNodeText(val){
            this.selectCell.attr('label/text', this.drawerNode.nodeText)
            this.selectCell.setData({
                graphical: {
                    name: val,
                }
            })
        }
    },
};
</script>
<style>
.el-color-dropdown {
    z-index: 6000 !important;
}
.el-select-dropdown {
    z-index: 6000 !important;
}
.el-tooltip__popper {
    z-index: 6000 !important;
}
.hide .el-upload--picture-card {
  display: none;
}
.el-color-dropdown__main-wrapper {
    display: none;
}
</style>
<style lang="less" scoped>
    @import '../../../css/iotConfigManage/rightDrawer.less';
    /deep/.el-form-item__label {
        padding: 0 12px 0 0;
    }
</style>
